
import { computed, defineComponent, PropType, ref, Ref } from 'vue';
import { useIsArbitrationClaimAvailable } from '../composables';
import { ancillaryOption, ArbitrationClaim, ArbitrationOptionName } from '../types';
import { openArbitrationClaimModal, serverSyncedNewDate } from '../utils';
import moment from 'moment-timezone';

import AppButton from './AppButton.vue';
import AppTooltip from './AppTooltip.vue';
import CountdownTimer from './CountdownTimer.vue';
import StatusTag from './StatusTag.vue';

export default defineComponent({
    name: 'TheOpenArbitrationClaimButton',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        buyerStoreId: {
            type: Number,
            required: true,
        },
        vehicleDeliveryDate: {
            type: Date,
            required: false,
        },
        selectedArbitrationOption: {
            type: Object as PropType<ancillaryOption>,
            required: false,
        },
        arbitrationExpirationDate: {
            type: Date,
            required: false,
        },
        openedArbitrationClaim: {
            type: Object as PropType<ArbitrationClaim>,
            required: false,
        }
    },
    components: {
        AppButton,
        AppTooltip,
        CountdownTimer,
        StatusTag,
    },
    setup(props, { emit, attrs }) {
        const defaultArbitrationOption: ArbitrationOptionName = 'standardArbitration';
        const hasArbitrationClaim: Ref<boolean> = ref(Boolean(props.openedArbitrationClaim));
        const arbitrationClaim: Ref<Partial<ArbitrationClaim>> = ref(props.openedArbitrationClaim);

        const isArbitrationStatusActionRequired = computed(() => {
            if (!arbitrationClaim.value?.arbitrationStatus) {
                return false;
            }
            const actionRequiredStatuses = ['Concession Offered', 'Offer Rejected', 'Information Requested'];
            return actionRequiredStatuses.includes(arbitrationClaim.value.arbitrationStatus)
        });

        const {
            isArbitrationAvailable,
            hasArbitrationTimeRemaining,
            arbitrationTimeRemaining,
            arbitrationUnavailableReason,
        } = useIsArbitrationClaimAvailable({
            hasArbitrationClaim,
            selectedArbitrationOption: props.selectedArbitrationOption ? props.selectedArbitrationOption.name as ArbitrationOptionName : defaultArbitrationOption,
            vehicleDeliveryDate: props.vehicleDeliveryDate ? new Date(props.vehicleDeliveryDate) : undefined,
            arbitrationExpirationDate: props.arbitrationExpirationDate ? new Date(props.arbitrationExpirationDate) : undefined,
            buyerStoreId: props.buyerStoreId,
        });

        function openCreateArbitrationClaimModal() {
            openArbitrationClaimModal(props.vehicleListingId, {
                events: {
                    claimOpened: () => {
                        hasArbitrationClaim.value = true;
                        arbitrationClaim.value = {
                            updatedDate: new Date(),
                            arbitrationStatus: 'Opened',
                        };
                    }
                }
            });
        }

        return {
            moment,
            arbitrationClaim,
            isArbitrationStatusActionRequired,
            openCreateArbitrationClaimModal,
            serverSyncedNewDate,
            isArbitrationAvailable,
            hasArbitrationTimeRemaining,
            arbitrationTimeRemaining,
            arbitrationUnavailableReason,
        }
    },
});
