var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex-row"},[(_vm.titleStatusFormatted)?_c('AppStatusIcon',_vm._b({},'AppStatusIcon',{
            tooltip: _vm.titleStatusFormatted.label,
            class: _vm.titleStatusFormatted.class,
            icon: 'clipboard-outline',
            ..._vm.$attrs,
        },false)):_vm._e(),(_vm.deliveryStatusFormatted)?_c('AppStatusIcon',_vm._b({},'AppStatusIcon',{
            tooltip: _vm.deliveryStatusFormatted.label,
            class: _vm.deliveryStatusFormatted.class,
            icon: 'truck-fast-outline',
            ..._vm.$attrs,
        },false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }