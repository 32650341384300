var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',{staticClass:"carmigo-modal",staticStyle:{"min-width":"500px"},attrs:{"isLoading":_vm.loadingSubmitOffer,"disableConfirm":(!_vm.selectedStoreId && !_vm.isUserSeller) || !_vm.offerAmountInt,"customButtons":(_vm.offerExpirationDateHasExpired && !_vm.resetOffer),"customLoading":""},on:{"close":function($event){return _vm.$emit('close')},"confirm":_vm.submitOffer},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s((_vm.hasCurrentOffer && !_vm.offerExpirationDateHasExpired) ? 'Update' : 'Submit')+" your offer ")]},proxy:true},{key:"body",fn:function(){return [_c('InfoHeader',{attrs:{"vehicleListingId":_vm.vehicleListingId,"imgSize":"sm-medium"},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('div',{staticClass:"flex-row justify-center"},[_c('InfoHighlight',_vm._b({},'InfoHighlight',{
                            label: _vm.isSecondChance ? 'Reserve price' : 'Floor price',
                            info: _vm.toCurrency(_vm.reservePrice),
                            color: 'green',
                        },false)),(!_vm.isSecondChance)?_c('InfoHighlight',{attrs:{"label":"Buy-it-now price","info":_vm.toCurrency(_vm.buyItNowPrice),"color":"green"}}):_vm._e(),(_vm.hasCurrentOffer && !_vm.offerExpirationDateHasExpired)?_c('InfoHighlight',{attrs:{"label":"Your current offer","info":_vm.isUserSeller ? _vm.toCurrency(_vm.currentOffer.sellerOfferAmount) : _vm.toCurrency(_vm.currentOffer.buyerOfferAmount),"color":"green"}}):_vm._e()],1)]},proxy:true}])}),(!_vm.offerExpirationDateHasExpired || _vm.resetOffer)?_c('div',{staticClass:"width-100"},[(!_vm.isUserSeller)?_c('TheStoreSelectInput',{staticClass:"width-100 mb-3",attrs:{"stores":_vm.stores,"actionType":"offering","expanded":"","initialStoreId":_vm.initialStoreIdUpdated,"disabled":_vm.disableStoreSelect},on:{"input":function($event){_vm.selectedStoreId = $event}}}):_vm._e(),_c('div',[(!_vm.isUserSeller)?_c('p',{staticClass:"bold"},[_vm._v(" Enter an offer of at least "+_vm._s(_vm.toCurrency(_vm.lowestPossibleOffer)))]):_c('p',{staticClass:"bold"},[_vm._v(" Submit an offer")]),_c('InputField',{attrs:{"useCustomStyles":false,"type":"currency","customValidator":_vm.validateOfferAmount,"customInvalidMessage":_vm.invalidOfferMessage},model:{value:(_vm.offerAmountStr),callback:function ($$v) {_vm.offerAmountStr=$$v},expression:"offerAmountStr"}}),(!_vm.isSecondChance && _vm.showInvalidMessageBuyItNow)?_c('div',{staticClass:"invalid-buy-it-now is-size-7 has-text-danger"},[_c('p',[_vm._v("You cannot place an offer higher or equal to the Buy-It-Now price of "+_vm._s(_vm.toCurrency(_vm.buyItNowPrice))+".")]),(!_vm.isUserSeller)?_c('p',[_vm._v("To save time & money, just buy it now.")]):_vm._e()]):_vm._e(),_c('TheNegotiationMessageInput',{staticClass:"mt-3",attrs:{"inputAttrs":{
                        placeholder: 'Add an optional message'
                    }},on:{"input":function($event){_vm.messageInput = $event}}})],1)],1):_c('div',{staticClass:"width-100"},[_c('div',[_c('p',[_vm._v(" This offer has expired. Would you like to submit a new offer? ")])])])]},proxy:true},{key:"buttonsRight",fn:function(){return [_vm._t("modalButtons",function(){return [_c('div',[_c('AppButton',{attrs:{"type":"is-danger","label":"Cancel"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('AppButton',{attrs:{"type":"is-primary","label":"New offer"},on:{"click":function($event){_vm.resetOffer = true}}})],1)]})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }