import { Ref, onMounted, onUnmounted, ref, shallowRef, unref, computed, watch } from "vue"
import { database } from '@/firebase';
import { updateWatchlist } from '@/api';
import { HighestBidRefRecord, HighestBidRefSnapshot } from "@/types";
import { dollarAmountToInt, getBuyItNowUnavailableReason, isBuyItNowAvailable } from "@/utils";
import { validatePrice } from "@/validation";

export function useSingleAuctionListener(
    vehicleListingId: number,
    onSnapshot: (payload: HighestBidRefSnapshot) => void
) {
    type DatabaseRef = ReturnType<typeof database['ref']>;

    let highestBidDatabaseRef: DatabaseRef | null = null;
    let listener: ReturnType<DatabaseRef['on']> | null = null;
    const highestBidSnapshot = ref<HighestBidRefRecord | null>(null);

    onMounted(() => {
        onHighestBidRefChange();
    });

    onUnmounted(() => {
        if (highestBidDatabaseRef && listener) {
            highestBidDatabaseRef.off('value', listener);
        }
    });

    function onHighestBidRefChange() {
        const highestBidRef = database.ref(`highestBid/${vehicleListingId}`);
        highestBidDatabaseRef = highestBidRef;

        listener = highestBidRef.on('value', (snapshot) => {
            if (!snapshot.exists()) {
                return;
            }

            const oldValue = highestBidSnapshot.value as HighestBidRefRecord | null;
            const newValue = snapshot.val() as HighestBidRefRecord;
            onSnapshot({
                isInitialSnapshot: !oldValue,
                oldSnapshotValue: oldValue,
                newSnapshotValue: newValue,
            });
            highestBidSnapshot.value = newValue;
        });
    }
}

export function useUpdateAuctionListingCardOnRdbChange() {
    const highestBid = ref<HighestBidRefRecord>({});

    function updateAuction({ isInitialSnapshot, oldSnapshotValue, newSnapshotValue }: HighestBidRefSnapshot) {
        highestBid.value = newSnapshotValue;
    }

    return {
        updateAuction,
        highestBid,
    }
}

export function useUpdateWatchlistStatus(vehicleListingId: Ref<number> | number, {
    emit,
}: {
    emit: (event: 'update:watching'| 'watching' | undefined, value: boolean) => void
}) {
    async function updateWatchlistStatus(value: boolean) {
        emit('update:watching', value);
        const { success } = await updateWatchlist(unref(vehicleListingId), value);
        if (!success) {
            emit('update:watching', !value);
        } else {
            emit('watching', value);
        }
    }

    return {
        updateWatchlistStatus,
    };
}

export function useValidatePricing(pricing: Ref<{
    startingPrice: string,
    reservePrice: string,
    buyItNowPrice: string,
    canBuyItNow: boolean,
}>) {
    const startingPriceInt = computed(() => dollarAmountToInt(pricing.value.startingPrice));
    const reservePriceInt = computed(() => dollarAmountToInt(pricing.value.reservePrice));
    const buyItNowPriceInt = computed(() => dollarAmountToInt(pricing.value.buyItNowPrice));

    const isStartingPriceValid = computed(() => {
        return !Boolean(validatePrice(startingPriceInt.value, 'starting', { isUndefinedInvalid: false }));
    });

    const startingPriceInvalidReason = computed(() => {
        return validatePrice(startingPriceInt.value, 'starting', { isUndefinedInvalid: false });
    });

    const isReservePriceValid = computed(() => {
        return !Boolean(validatePrice(reservePriceInt.value, 'reserve', { isUndefinedInvalid: false }));
    });

    const reservePriceInvalidReason = computed(() => {
        return validatePrice(reservePriceInt.value, 'reserve', { isUndefinedInvalid: false });
    });

    const isBuyItNowPriceValid = computed(() => {
        if (!pricing.value.canBuyItNow) {
            return true;
        }
        if (!buyItNowPriceInt.value) {
            return false;
        }
        return isBuyItNowAvailable({
            startingPrice: startingPriceInt.value,
            reservePrice: reservePriceInt.value,
            buyItNowPrice: buyItNowPriceInt.value,
        });
    });

    const buyItNowPriceInvalidReason = computed(() => {
        if (!pricing.value.canBuyItNow) {
            return undefined;
        }
        if (!buyItNowPriceInt.value) {
            return 'Required if Buy-It-Now is enabled';
        }
        return getBuyItNowUnavailableReason({
            startingPrice: startingPriceInt.value,
            reservePrice: reservePriceInt.value,
            buyItNowPrice: buyItNowPriceInt.value,
        });
    });
    
    return {
        isStartingPriceValid,
        startingPriceInvalidReason,
        isReservePriceValid,
        reservePriceInvalidReason,
        isBuyItNowPriceValid,
        buyItNowPriceInvalidReason,
    }
}