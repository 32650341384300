
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ThePowerUserIcon',
    props: {
        userType: {
            type: String as PropType<'seller' | 'buyer' | 'consumer'>,
            required: true,
        },
        iconType: {
            type: String as PropType<'white-background'>,
            required: false,
        },
        showTooltip: {
            type: Boolean,
            default: true,
        }
    },
    setup(props) {
        const allBadgeDetails = {
            seller: {
                tooltipText: 'Power Seller',
                assetName: props.iconType == 'white-background' ? 'badge-power-seller-white' : 'power-seller-badge',
                imgAlt: 'power seller badge',
            },
            buyer: {
                tooltipText: 'Power Buyer',
                assetName: props.iconType == 'white-background' ? 'badge-power-buyer-white-background' :'icons/PowerBuyer_horiz',
                imgAlt: 'power buyer badge',
            },
            consumer: {
                tooltipText: 'Consumer',
                assetName: props.iconType == 'white-background' ? 'badge-consumer-white' :'consumer-badge',
                imgAlt: 'consumer seller badge',
            }
        }
        return {
            badgeDetails: allBadgeDetails[props.userType],
        }
    }
});
