import { getActivityFeedByVehicleListingId } from '@/api';
import { NoteDTO } from '@/types';
import { Ref, ref } from 'vue';

export function useActivityFeed({ vehicleListingId }: {
    vehicleListingId: number,
}) {
    const loadingActivityFeed: Ref<boolean> = ref(false);
    const activityFeed: Ref<NoteDTO[]> = ref([]);
    async function getActivityFeed() {
        loadingActivityFeed.value = true;
        activityFeed.value = await getActivityFeedByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingActivityFeed.value = false,
            onError: () => loadingActivityFeed.value = false,
        });
    }

    return {
        getActivityFeed,
        activityFeed,
        loadingActivityFeed,
    }
}