import router from '@/router';
import store from '@/vuex';
import { Route } from '@sentry/vue/types-ts3.8/router';
import { isEqual } from 'lodash';

export function openLink(href: string, { openInNewTab=true }: { openInNewTab?: boolean }={}) {
  window.open(href, openInNewTab ? '_blank' : undefined);
}

export function redirectByRole() {
  const { path: redirectPathByRole } = getRedirectPathByRole();
  router.push(router.app.$route.query?.redirect as string | undefined ?? redirectPathByRole);
}

export function getRedirectPathByRole() {
  if (store.getters.isUserBuyer) {
    return { path: '/live-auctions' };
  }

  if (store.getters.isUserAdmin || store.getters.isUserDsr) {
    return { path: '/admin' };
  }

  if (store.getters.isUserInspector) {
    return { path: '/inspector' };
  }

  if (store.getters.isUserSeller) {
    return { path: '/dashboard/selling/0' };
  }

  if (store.getters.isUserCarmigoDirect) {
    return { path: '/carmigoDirect' };
  }

  if (store.getters.isUserStoreManager) {
    return { path: '/stores' };
  }

  return { path: '/home' };
}

export function browserBaseUrl() {
  return process.env.VUE_APP_BROWSER_BASE_URL;
}

/**
 * Redirect to a specified route or go back in history if allowed.
 *
 * @param {Object} options - The options for redirection.
 * @param {string} options.name - The name of the route to navigate to.
 * @param {Object=} options.params - Optional parameters to pass to the route.
 * @param {Object=} options.query - Optional query parameters to pass to the route.
 * @param {boolean=} options.canGoBack - If true, attempt to go back in the browser's history.
 * @param {boolean=} options.preserveQuery - If true, preserve the current query parameters.
 * @param {string[]=} options.omitQuery - An array of query parameters to omit from the new route.
 * @param {boolean=} options.preserveParams - If true, preserve the current route parameters.
 * 
 * @returns {Promise} A Promise that resolves once the navigation is complete.
 */
export function redirectTo({
    name,
    params,
    query,
    canGoBack,
    preserveQuery,
    omitQuery,
    preserveParams,
    openInNewTab,
}: {
  name: string,
  params?: Record<string, any>,
  query?: Record<string, any>,
  canGoBack?: boolean
  preserveQuery?: boolean
  omitQuery?: string[]
  preserveParams?: boolean,
  openInNewTab?: boolean,
}) {
    if (canGoBack && window.history.length > 1) {
        return router.go(-1);
    }

    const currentQuery = { ...(preserveQuery ? router.currentRoute.query : {}) };

    if (omitQuery) {
        omitQuery.forEach((param) => {
            delete currentQuery[param];
        });
    }
    if (openInNewTab) {
      let route = router.resolve({ name, params, query });
      window.open(route.href, '_blank');
      return;
    }

    return router.push({
        name,
        query: { ...currentQuery, ...query },
        params: { ...(preserveParams ? router.currentRoute.params : {}), ...params },
    });
}

export function redirectToTurnWithSorting(field: string, order: string) {
    return redirectTo({ name: 'Turn', query: { sortBy: field, order }, preserveQuery: true });
}

export type UpdateUrlParams = {
  route: Route, 
  newQueryParams: { [key: string]: string | (string | null)[] | null | undefined }, 
  maintainParams?: string[],
  maintainAllParams?: boolean,
}
export function updateUrlParams({ route, newQueryParams, maintainParams, maintainAllParams }: UpdateUrlParams) {
  let urlParams = route.query;

  let maintainedQueryParams: { [x: string]: string | (string | null)[] | null; } = {};
  // if maintainAllParams is provided, will only overwrite newQueryParams
  if (maintainAllParams) {
    maintainedQueryParams = { ...urlParams };
  }
  
  // if maintainParams is provided, will overwrite all others and only maintain those
  maintainParams?.forEach(param => {
    maintainedQueryParams[param] = urlParams[param] as string | string[];
  });

  let updatedQuery = {
    ...maintainedQueryParams,
    ...newQueryParams,
  }

  if (isEqual(updatedQuery, urlParams)) {
    return;
  }

  router.replace({
      path: route.path,
      query: {
          ...maintainedQueryParams,
          ...newQueryParams,
      }
  });
}

export function goToVDP(vehicleListingId: number, { openInNewTab=true }: { openInNewTab?: boolean }={}) {
  if (router.currentRoute?.fullPath) {
    store.commit('updateMyLastPath', router.currentRoute.fullPath);
  }
  let vdpRoute = router.resolve({
    name: 'Auction Details',
    params: {
      id: vehicleListingId.toString(),
      activeTab: '0',
    }
  });
  window.open(vdpRoute.href, openInNewTab ? '_blank' : undefined);
}

export function goToLastPath() {
  if (router.app.$route.name == 'Inspection Details') {
    router.push('/inspector');
    return;
  }
  if (!store.state.lastPath) {
    return;
  }
  router.push(store.state.lastPath);
}

export function getLastPathName() {
  if (router.app.$route.name == 'Inspection Details') {
    return 'Back to Inspections';
  }
  if (!store.state.lastPath) {
    return '';
  }
  if (store.state.lastPath.includes('admin')) {
    return 'Back to Admin Dashboard';
  }
  if (store.state.lastPath.includes('dashboard')) {
    return 'Back to Dashboard';
  }
  if (store.state.lastPath.includes('auctions')) {
    return 'Back to Listings';
  }
  return 'Back';
}
