import { getArbitrationRateBySellerStoreId, getSellRateBySellerStoreId, getTimeToTitleBySellerStoreId, PUT } from "@/api";
import { GetActiveListingsFilterDTO, SellerStat, SRPListing, TimeDTO } from "@/types";
import { convertFilterToFilterSchema, formatSellerStat, getPaginationOffset, openErrorDialog, UpdateUrlParams } from "@/utils";
import { computed, onBeforeMount, onMounted, Ref, ref, SetupContext, watch } from "vue";
import { useRoute } from 'vue-router/composables';
import { LooseRequired } from "vue/types/common";
import { useUser } from "./user";
import { useCancelToken, useFetch } from './fetch';
import { useStore } from "./useStore";

export interface UpdateListingsCallbackParams {
    listings: any[],
    fromFilterUpdate?: boolean
}

/**
 * Handles pagination for the SRP - fetching listings on ScrollObserver or filters update 
 * @param props - used to watch the loadMoreListingsKey, which is used to fetch more paginated listings
 * @param context - used to emit 'canLoadMore' and 'loadingMore' 
 * @param status - the status of the listings to fetch ('Auctioning' or 'InMarketplace')
 * @param updateListingsCallback - when the listings have been fetched, perform custom actions
 * @param customPaginationLimit - default 36
 * @returns an array of listings from vehicles/getActiveListings
 */
export function useGetActiveListings({ props, context, status, updateListingsCallback, customPaginationLimit, initialFilters }: {
    props: Readonly<Readonly<LooseRequired<{ loadMoreListingsKey: number; } & {}>>>,
    context: SetupContext<("canLoadMore" | "loadingMore")[]>, 
    status: 'Auctioning' | 'InMarketplace',
    updateListingsCallback: (payload: UpdateListingsCallbackParams) => void,
    customPaginationLimit?: number,
    initialFilters?: GetActiveListingsFilterDTO,
}) {
    let paginationOffset = 0;
    let paginationLimit = customPaginationLimit ?? 36;
    let canLoadMoreListings = false;
    let currentFilters: GetActiveListingsFilterDTO | undefined = initialFilters;
    const loadingListings = ref(false);

    const user = useUser();

    onMounted(() => {
        if (initialFilters) {
            loadingListings.value = true;
        } else {
            getActiveListings();
        }
    });

    // toggle the component's loadMoreListingsKey prop to load more paginated listings
    watch(() => props.loadMoreListingsKey, () => {
        getActiveListings(currentFilters, true);
    });

    // call from component on filter update to get listings matching the filter
    function getActiveListingsFromFilter(filter: GetActiveListingsFilterDTO | undefined = undefined) {
        getActiveListings(filter, false, true);
    }

    async function getActiveListings(filters: GetActiveListingsFilterDTO | undefined = undefined, fromPagination: boolean=false, fromFilterUpdate: boolean=false) {
        if (fromFilterUpdate) {
            context.emit('canLoadMore', false);
            paginationOffset = 0;
        }
        toggleLoadingListings(fromPagination, true);
        currentFilters = filters;
        await PUT(`/vehicles/getActiveListings`, {
            filters: currentFilters,
            status,
            paginationOffset,
            paginationLimit,
        }).then(res => {
            toggleLoadingListings(fromPagination, false);
            canLoadMoreListings = res.data.length >= paginationLimit;
            const validListings = res.data.filter((listing: any) => Boolean(listing));
            paginationOffset += validListings.length;
            context.emit('canLoadMore', canLoadMoreListings);
            updateListingsCallback({
                listings: validListings,
                fromFilterUpdate
            });
        }).catch(error => {
            toggleLoadingListings(fromPagination, false);
            const listingType = status == 'Auctioning' ? 'Live Auctions' : 'Marketplace listings';
            openErrorDialog({
                title: `Could not fetch ${listingType}`,
                message: `We encountered an error while fetching ${listingType} for person ${user.value?.profile?.id}`,
                error,
            });
        });
    }

    // toggles loading feedback on the component
    // or emits 'loadingMore' to toggle SRP loading feedback while fetching more paginated listings
    function toggleLoadingListings(fromPagination: boolean, value: boolean) {
        fromPagination
            ? context.emit('loadingMore', value)
            : loadingListings.value = value;
    }

    return {
        getActiveListings,
        getActiveListingsFromFilter,
        loadingListings,
    }
}

export function useGetListingTypeFromUrlParams() {
    let route = useRoute();
    
    let urlParams = route.query;
    
    // GET LISTING TYPE
    const urlListingType: Ref<ListingType> = ref('auction'); 
    let validListingTypes= ['auction', 'marketplace', 'secondChance'];
    if (validListingTypes.includes(urlParams.listingType as string)) {
        urlListingType.value = urlParams.listingType as ListingType;
    }

    return {
        urlListingType,
    }
}

export type ListingType = 'auction' | 'marketplace' | 'secondChance';

export function useListingType({ context, updateUrlParams, initialListingType }: {
    context: SetupContext<('updateListingType' | 'selectedListingType' | 'updateVehicleStatus')[]>,
    updateUrlParams?: ({}: UpdateUrlParams) => void, // leave undefined if you don't want to update URL params
    initialListingType?: ListingType,
}) {
    const selectedListingType: Ref<ListingType> = ref(initialListingType ?? 'auction');
    const route = useRoute();
    const listingTypeKey = ref(0);

    function updateSelectedListingType(listingType: ListingType) {
        selectedListingType.value = listingType;
    }

    watch(() => selectedListingType.value, () => {
        context.emit('updateListingType', selectedListingType.value);
        context.emit('updateVehicleStatus', getVehicleStatusFromListingType.value);
        if (updateUrlParams) {
            updateUrlParams({
                route, 
                newQueryParams: { 'listingType': selectedListingType.value }, 
                maintainAllParams: true,
            });
        }
    });

    const { urlListingType } = useGetListingTypeFromUrlParams();
    onBeforeMount(() => {
        selectedListingType.value = urlListingType.value ?? 'auction';
    });

    const getVehicleStatusFromListingType = computed(() => {
        let status;
        switch (selectedListingType.value) {
            case 'auction':
                status = 'Auctioning';
                break;
            case 'marketplace':
                status = 'InMarketplace';
                break;
            case 'secondChance':
                status = 'SecondChance';
                break;
        }
        return status;
    });

    return {
        selectedListingType,
        updateSelectedListingType,
        listingTypeKey,
        getVehicleStatusFromListingType,
    }
}

/**
 * This composable is used to manage active listings.
 *
 * @param {Object} options - The options for fetching active listings.
 * @param {Record<string, unknown>} options.filters - The filters to apply when fetching active listings.
 * @param {Array<string>} [options.orderBy = []] - The order in which to sort the fetched listings.
 * @param {number} [options.paginationLimit = 10] - The maximum number of listings to fetch.
 * @param {Function} options.emit - The function to call when the listings have been fetched.
 *
 * @returns {Object} An object containing the live listings and the total number of results available.
 * @returns {Array<Object>} .liveListings - The live listings.
 * @returns {number} .totalResultsAvailable - The total number of results available.
 * @returns {boolean} .isLoadingListings - A boolean indicating if the listings are being loaded.
 */
export function useActiveListings({
    filters,
    orderBy = [],
    paginationLimit = 50,
    context,
}: {
    filters: Parameters<typeof convertFilterToFilterSchema>[0]
    orderBy?: Array<string>
    paginationLimit?: number
    context: SetupContext<('fetchedListings' | 'toggleLoading')[]>
}): {
    liveListings: Ref<SRPListing[]>,
    isLoadingListings: Ref<boolean>,
    totalResultsAvailable: Ref<number>,
} {
    const liveListings: Ref<SRPListing[]> = ref([]);
    const isLoadingListings = ref(false);
    const totalResultsAvailable = ref(0);
    const route = useRoute();
    const cancelToken = useCancelToken();

    async function getActiveListings(): Promise<{ listings: SRPListing[], totalResultsAvailable: number }>{
        isLoadingListings.value = true;
        context.emit('toggleLoading', isLoadingListings.value);
        try {
            const { data } = await PUT<{
                listings: SRPListing[]
                totalResultsAvailable: number
            }>('/vehicles/getActiveListings', {
                modifiers: {
                    filters: convertFilterToFilterSchema(filters),
                    orderBy,
                    pagination: {
                        paginationLimit,
                        paginationOffset: getPaginationOffset(Number(route.query.page ?? 1), paginationLimit, totalResultsAvailable.value),
                    },
                },
            }, {
                cancelToken,
            });
            return data;
        } catch {
            // TODO: Handle error
            return {
                listings: [],
                totalResultsAvailable: 0,
            };
        } finally {
            isLoadingListings.value = false;
            context.emit('toggleLoading', isLoadingListings.value);
        }
    }

    async function fetchAndSetListings() {
        const { listings, totalResultsAvailable: total } = await getActiveListings();
        liveListings.value = listings;
        totalResultsAvailable.value = total;
        context.emit('fetchedListings', total);
    }

    watch(() => route.query.page, () => {
        fetchAndSetListings();
    }, { immediate: true });

    return {
        liveListings,
        isLoadingListings,
        totalResultsAvailable,
    };
}

export function useRemoveListingRow(listings: Ref<SRPListing[]>) {
    function removeListingRow(vehicleListingId: number) {
        window.setTimeout(() => {
            listings.value = listings.value.filter(listing => listing.id !== vehicleListingId);
        }, 500);
    }

    const removeListingRowQueue: Ref<number[]> = ref([]);
    function queueRemoveListingRow(vehicleListingId: number) {
        removeListingRowQueue.value.push(vehicleListingId);
    }

    function executeRemoveListingRowQueue() {
        removeListingRowQueue.value.forEach(vehicleListingId => {
            removeListingRow(vehicleListingId);
        });
    }

    return {
        removeListingRow,
        removeListingRowQueue,
        queueRemoveListingRow,
        executeRemoveListingRowQueue,
    }
}

export function useTransporationCost({ listingZipCode, vehicleListingId, context }: { 
    listingZipCode: string, 
    vehicleListingId: number,
    context?: SetupContext<( 'transportationCost' | any )[]>,
}) {
    const store = useStore();
    const zip1 = store.getters.getTransportationZip;
    if (!listingZipCode || !zip1) {
        return {
            transportationCost: ref(undefined),
            isLoadingTransportationCost: ref(false),
        }
    }

    const {
        data,
        loading,
    } = useFetch<number>(`/distance/calculateTransportationCostUsingZipCodes`, {
            method: 'PUT',
            data: {
                zip1, 
                zip2: listingZipCode,
                vehicleListingId,
            },
            onSuccess: () => {
                    if (context) {
                        context.emit('transportationCost', data.value);
                    }
                }
            });

    return {
        transportationCost: data,
        isLoadingTransportationCost: loading,
    };
}

export function useFetchSellingStat({ sellerStoreId, statName }: {
    sellerStoreId: number,
    statName: SellerStat,
}) {
    const sellingStat: Ref<number | string | undefined> = ref(undefined);
    const loadingStat: Ref<boolean> = ref(false);

    const fetchStatFunction: { [key: string]: (sellerStoreId: number) => Promise<number | TimeDTO | undefined>} = {
        'Arbitration Rate': getArbitrationRateBySellerStoreId,
        'Sell Rate': getSellRateBySellerStoreId,
        'Time to Title': getTimeToTitleBySellerStoreId,
    }

    onBeforeMount(async () => {
        loadingStat.value = true;
        let fetchFunction = fetchStatFunction[statName];
        if (!fetchFunction) {
            loadingStat.value = false;
            return;
        }
        await fetchFunction(sellerStoreId)
            .then(res => {
                sellingStat.value = formatSellerStat(statName, res);
                loadingStat.value = false;
            }).catch(error => {
                loadingStat.value = false;
                console.log('error', error);
            });
    });
    return {
        sellingStat,
        loadingStat,
    }
}
