<template>
    <div class="bidding-buttons flex-column width-100">
        <div 
            class="top-row"
            :class="`${format} ${format == 'compact' ? 'flex-row' : 'flex-column'}`"
        >
            <AppButton 
                v-show="showButtons == 'bid' || showButtons?.includes('bid')"
                v-if="highestBid.status === 'Auctioning'"
                v-bind="{
                    label: 'Bid + $100',
                    type: 'is-primary',
                    disabled: disableAllBidButtons || isHighestBidder,
                    loading: isLoading,
                    outlined: true,
                    expanded: true,
                    ...$attrs
                }"
                @click.stop="openBidModal('bid')"
            />

            <AppButton 
                v-show="showButtons == 'proxy' || showButtons?.includes('proxy')"
                v-if="highestBid.status === 'Auctioning'"
                v-bind="{
                    label: 'Proxy Bid',
                    type: 'is-primary',
                    disabled: disableAllBidButtons,
                    loading: isLoading,
                    outlined: true,
                    expanded: true,
                    ...$attrs,
                }"
                @click.stop="openBidModal('proxy')"
            />
        </div>

        <div 
            class="bottom-row" :class="`${format}`"
            v-show="showButtons == 'buyItNow' || showButtons?.includes('buyItNow')"
            v-if="canBuyItNow && buyItNowPrice"
        >
            <AppButton 
                v-bind="{
                    type: 'is-primary',
                    disabled: disableAllBidButtons || disableBuyItNowButton,
                    loading: loadingStores,
                    expanded: true,
                    ...$attrs,
                }"
                @click.stop="$emit('click'); openBidModal('buyItNow')"
            >
                <div>
                    <span class="buy-now">Buy It Now </span>
                    <span class="buy-now-price">{{ buyItNowPrice ? buyItNowPrice : reservePrice | currency }}</span>
                </div>
            </AppButton>
        </div>
    </div>
</template>

<script>
import store from '@/vuex';
import {
    isBuyItNowAvailable,
    isUserLogged,
    isUserBuyer,
} from '../../utils';

import AppButton from '../AppButton.vue';
import BidModal from '../BidModal.vue';

export default {
    name: 'BiddingButtons',
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        isCheckoutCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        highestBid: {
            type: Object,
            required: true,
        },
        buyItNowPrice: {
            type: Number,
            required: false,
        },
        reservePrice: {
            type: Number,
            required: true,
        },
        sellerPersonId: {
            type: Number,
            required: false,
        },
        userProxyBid: {
            type: Number,
            required: false
        },
        auctionOver: {
            type: Boolean,
            required: false,
        },
        showButtons: {
            type: [String, Array],
            required: false,
            default: () => ['bid', 'proxy', 'buyItNow']
        },
        format: {
            type: 'stacked' | 'compact',
            required: false,
            default: 'stacked'
        }
    },
    components: {
        AppButton,
    },
    data() {
        return {
            isLoading: false,
            loadingStores: false,
            buyerStores: store?.state?.user?.profile?.stores,
            disableBuyItNow: false,
        }
    },
    computed: {
        isUserLogged,
        isUserBuyer,

        disableAllBidButtons() {
            return this.isCheckoutCompleted
                || this.highestBid.status !== 'Auctioning'
                || !this.isUserLogged
                || !this.isUserBuyer
                || this.auctionOver
                || this.isVehicleSeller;
        },
        
        isVehicleSeller() {
            return this.sellerPersonId == store.state.user.profile.id;
        },

        disableBuyItNowButton() {
            return this.disableBuyItNow 
                || !this.canBuyItNow
                || this.isCheckoutCompleted
                || (this.highestBid.status !== 'Auctioning' && this.highestBid.status !== 'NotSold')
                || this.highestBid.amount >= this.reservePrice
                || (this.buyItNowPrice ? this.highestBid.amount >= this.buyItNowPrice : false)
                || !this.isUserLogged
                || !this.isUserBuyer;
        },

        isHighestBidder() {
            if (!this.isUserLogged || !this.highestBid.buyer) {
                return;
            }
            return store.state.user.profile.id == this.highestBid.buyer.id;
        },

        canBuyItNow() {
            return isBuyItNowAvailable({
                vehicleListingId: this.vehicle.id, 
                buyItNowPrice: this.vehicle?.auction?.buyItNowPrice,
                dbCanBuyItNow: this.vehicle?.auction?.canBuyItNow,
                reservePrice: this.vehicle?.auction?.reservePrice,
                startingPrice: this.vehicle.auction?.askingPrice,
                auctionEndDate: this.highestBid?.auctionEnd ? new Date(this.highestBid.auctionEnd) 
                    : this.vehicle.auction?.auctionEnd ? new Date(this.vehicle.auction?.auctionEnd) 
                    : undefined,
                highestBid: this.highestBid?.amount,
            })
        },
    },
    methods: {
        openBidModal(bidType) {
            if (this.openModal) {
                return;
            }
            this.openModal = this.$buefy.modal.open({
                parent: this,
                component: BidModal,
                props:  {
                    carId: this.vehicle.id,
                    highestBidAmount: this.highestBid.amount,
                    storesInitial: this.buyerStores,
                    bidType,
                    existingProxy: this.userProxyBid,
                    car: {
                        brand: this.vehicle.brand,
                        model: this.vehicle.model,
                        color: this.vehicle.color,
                        year: this.vehicle.year,
                        user: this.vehicle.user,
                        auctionStartDate: this.vehicle.auction?.auctionStart,
                    },
                    buyItNowPrice: this.buyItNowPrice,
                    canBuyItNow: this.canBuyItNow,
                },
                hasModalCard: true,
                trapFocus: true,
                onCancel: () => this.openModal = null,
                events: {
                    onConfirmProxy: async () => {
                        this.isLoading = true;
                        this.$emit('getProxyBid');
                        this.isLoading = false;
                    },
                    onConfirmBuyItNow: () => {
                        this.$emit('fetchCar')
                    },
                    onEditProxy: () => this.editProxyBid(),
                    onConfirm: () => {
                        this.isLoading = true;
                        this.openModal = null;
                    },
                    onConfirmBid: () => {
                        this.$emit('onConfirmBid');
                    },
                    close: () => this.openModal = null
                }
            });
        },
    },
}
</script>

<style scoped lang='postcss'>
.button {
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    &.is-outlined {
        border: 1px solid var(--primary);
    }
}

.flex-row .button:not(:last-child) {
    margin-right: 0.5rem;
}

.bottom-row {
    &.stacked {
        margin-top: 0.55rem;
    }
}

</style>
