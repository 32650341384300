import { APIConfig, NoteDTO, VehicleCondition } from '@/types';
import { AdminVehicleDetailsDTO } from '@/types/AdminVehicleDetailsDTO';
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, formatCelebrateValidationError, formatErrorObj, openErrorDialog, openToast } from '@/utils';
import { GET, PUT } from '.';
import store from '@/vuex';

export async function updateWatchlist(vehicleListingId: number, value: boolean) {
    try {
        await PUT('/listings/updateWatchList', {
            vehicleListingId,
            addWatchListItem: value,
        });
        return { success: true };
    } catch (error) {
        openErrorDialog({
            title: 'Could not update watchlist status',
            message: `We encountered an error while updating the watch list status for vehicle with listing ID ${vehicleListingId}`,
            error,
        });
        return { success: false };
    }
}

export async function getAdminVehicleDetailsDTO(vehicleListingId: number, config: APIConfig={}): Promise<AdminVehicleDetailsDTO & { vehicleCondition: VehicleCondition }> {
    return await GET(`/vehicles/${vehicleListingId}/getVehicleDetails`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch vehicle details',
                message: `We encountered an error while fetching vehicle details for vehicle ${vehicleListingId}`,
                error,
            });
        });
}

export async function selfAssignInspection(vehicleListingId: number, config: APIConfig={}): Promise<string> {
    return await PUT(`/inspection/selfAssignInspector`, { vehicleListingId })
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to assign inspection',
                message: `We encountered an error while assigning the listing ${vehicleListingId}'s inspection to the current user.`,
                error,
            });
        });
}

export async function assignInspectorToListing(personId: number | null, vehicleListingId: number, config: APIConfig={}) {
    return await PUT(`/inspection/updateInspector`, {
        inspectorPersonId: personId, 
        vehicleListingId,
    }).then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
    }).catch(error => {
        applyAPIConfigOnError(error, config);
        const celebrateValidationMessage = formatCelebrateValidationError(error);
        openErrorDialog({
            title: 'Failed to assign inspection',
            message: celebrateValidationMessage ?? `We encountered an error while assigning the inspection for vehicle ${vehicleListingId} to person ${personId}`,
            error,
        });
    });
}

export async function updateVehicleLocation(vehicleListingId: number, location: { zip?: string, city?: string, state?: string }, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}`, { ...location, id: vehicleListingId.toString() })
        .then(res => {
            if (config.onSuccess) {
                applyAPIConfigOnSuccess(res.data, config);
                return res.data;
            }
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            let celebrateValidationMessage = formatCelebrateValidationError(error);
            openErrorDialog( {
                title: 'Failed to update vehicle location',
                message: celebrateValidationMessage ?? `We encountered an error whiel updating the location for vehicle ${vehicleListingId}. ${formatErrorObj(location)}`,
                error,
            });
        });
}

export async function upvoteListing(vehicleListingId: number, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}/upvoteVehicle`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openToast('is-danger', 'Upvote failed');
        });
}

export async function removeUpvote(vehicleListingId: number, config: APIConfig={}) {
    return await PUT(`/vehicles/${vehicleListingId}/removeUpvote`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openToast('is-danger', 'Upvote failed');
        });
}

export async function getVehicleDetails(vehicleListingId: number, config: APIConfig={}): Promise<AdminVehicleDetailsDTO> {
    return await GET(`/vehicles/${vehicleListingId}/getVehicleDetails`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch vehicle details',
                message: `We encountered an error while fetching vehicle details for listing ${vehicleListingId}`,
                error,
            });
        });
}

export async function getAuctionDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/${vehicleListingId}/getAuctionDetails`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch auction details',
                message: `We encountered an error while fetching auction details for listing ${vehicleListingId}`,
                error,
            });
        });
}

export async function getVehicleSellerBasicDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/${vehicleListingId}/getSellerDetails`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch seller details',
                message: `We encountered an error while fetching seller details for listing ${vehicleListingId}`,
                error,
            });
        });
}

export async function getVehicleHighestBidderBasicDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/vehicles/${vehicleListingId}/getHighestBidderDetails`)
    .then(res => {
        applyAPIConfigOnSuccess(res.data, config);
        return res.data;
    }).catch(error => {
        applyAPIConfigOnError(error, config);
        openErrorDialog({
            title: 'Failed to fetch buyer details',
            message: `We encountered an error while fetching highest bidder details for listing ${vehicleListingId}`,
            error,
        });
    });
}

export async function getMarketplaceListingDetails(marketplaceListingId: number, config: APIConfig={}) {
    return await GET(`/marketplace/marketplaceListing/${marketplaceListingId}`)
        .then(res => {
            if (!res.data?.length) {
                return undefined;
            }
            applyAPIConfigOnSuccess(res.data[0], config);
            return res.data[0];
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch marketplace details',
                message: `We encountered an error while fetching marketplace details for listing ${marketplaceListingId}`,
                displayErrorInDialog: true,
                error,
            });
        });
}

export async function getActivityFeedByVehicleListingId(vehicleListingId: number, config: APIConfig={}): Promise<NoteDTO[]> {
    return await GET(`/vehicles/${vehicleListingId}/getActivityFeed`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch activity feed',
                message: `We encountered an error while fetching the activity feed for listing ${vehicleListingId}`,
                error,
            });
        });
}

export async function getCheckoutCount(config: APIConfig={}) {
    return await GET(`/vehicles/getCheckoutCount`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            store.commit('setActionRequiredCounts', { checkout: res.data });
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}

export async function getNegotiatingCount(config: APIConfig={}) {
    return await GET(`/vehicles/getNegotiatingCount`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            store.commit('setActionRequiredCounts', { negotiation: res.data });
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}