import { MarketPlaceOffererTypeEnum, MarketplaceOfferStatus } from '@/enums';
import store from '@/vuex';
import { marketplaceCurrentOfferDTO, VehicleFilter } from '@/types';
import { clearFilterField, getFilterFieldsWithFilterValues } from './filterUtils';
import { openToast } from './buefyUtils';
import { BModalComponent } from 'buefy/types/components';
import { getVueContextFromElement } from './otherUtils';
import { dateHasPassed } from './dateandTimeUtils';
import { goToDetails } from '@/dashboardUtils';

export function isMarketplaceOfferPending(offerStatusId: number, negotiatingUser: 'buyer' | 'seller'): Boolean {
    const offerStatus = MarketplaceOfferStatus[offerStatusId];
    switch (offerStatus) {
        case 'Active':
            return true;
        case 'RejectedByBuyer':
            return negotiatingUser == 'buyer';
        case 'RejectedBySeller':
            return negotiatingUser == 'seller';
        default: 
            return false;
    }
}

export function getMarketplaceOfferType(offerStatusId: number, offererTypeId: number, user: 'buyer' | 'seller'): 'myOffer' | 'notMyOffer' | 'rejectedOffer'  {
    const offerStatus = MarketplaceOfferStatus[offerStatusId];
    const offerer = MarketPlaceOffererTypeEnum[offererTypeId];
    const offererIsMe = offerer.toLowerCase() == user.toLowerCase();

    if (offererIsMe) {
        return 'myOffer';
    }
    switch (offerStatus) {
        case 'Active':
        default: 
            return 'notMyOffer';
        case 'RejectedByBuyer':
        case 'RejectedBySeller':
            return 'rejectedOffer';
    }
}

export function getMarketplaceOfferButtonLabel(offerStatusId: number, offererTypeId: number, user: 'buyer' | 'seller'): 'View Offer' | 'Manage Offer' | 'Offer Rejected' {
    let offerType: 'myOffer' | 'notMyOffer' | 'rejectedOffer' = getMarketplaceOfferType(offerStatusId, offererTypeId, user);

    switch (offerType) {
        case 'myOffer':
            return 'Manage Offer';
        case 'notMyOffer':
            return 'View Offer';
        case 'rejectedOffer': 
            return 'Offer Rejected';
    }
}

export function getMarketplaceOfferButtonType(offerStatusId: number, offererTypeId: number, user: 'buyer' | 'seller'): 'is-primary' | 'is-danger' | 'is-light' {
    let offerType: 'myOffer' | 'notMyOffer' | 'rejectedOffer' = getMarketplaceOfferType(offerStatusId, offererTypeId, user);
    switch (offerType) {
        case 'myOffer':
            return 'is-light';
        case 'notMyOffer':
            return 'is-primary';
        case 'rejectedOffer': 
            return 'is-danger';
    }
}

export function getInitialFiltersObject() {
    return {
        sellerType: [],
        year: [],
        make: [],
        model: [],
        trim: [],
        bodyType: [],
        distance: 0,
        price: [],
        fuelType: [],
        zip: '',
    }
}

// have to do this to maintain reactivity
export function clearAllFilters(filters?: VehicleFilter) {
    if (!filters) {
        return;
    }
    const filterFieldsToClear = getFilterFieldsWithFilterValues(filters);
    let updatedFilters = filters;
    filterFieldsToClear.forEach(field => updatedFilters = clearFilterField(filters, field as keyof VehicleFilter));
    return updatedFilters;
}

export function goToMarketplaceVDP(vehicleListingId: number, marketplaceListingId: number, activeTab: number=1, openInNewTab: boolean=false) {
    if (!vehicleListingId || !marketplaceListingId) {
        openToast('is-danger', 'Could not redirect to marketplace listing');
        return;
    }
    goToDetails({
        vehicleListingId: vehicleListingId.toString(),
        marketplaceListingId: marketplaceListingId.toString(),
        activeTab: activeTab.toString(),
        openInNewTab,
    });
}

export function selectMarketplaceOfferCountdownColor({ sellerPersonId, mostRecentOffererTypeId }: { sellerPersonId?: number | string, mostRecentOffererTypeId: number }): 'red' | 'black' {
  const usersPersonId = store.getters.getPersonId;
  // eslint-disable-next-line eqeqeq
  const isVehicleSeller = usersPersonId == sellerPersonId;

  // The most recent offer was from a buyer && the user is the seller of this listing
  // The most recent offer was from a seller && the user is the Buyer of this listing
  if (
      (mostRecentOffererTypeId === MarketPlaceOffererTypeEnum.Buyer && isVehicleSeller)
      || (mostRecentOffererTypeId === MarketPlaceOffererTypeEnum.Seller && !isVehicleSeller)
  ) {
      return 'red';
  }
      return 'black';
}

export function updateMarketplaceModalWithIncomingOffer({ modalClass, userType, displayUpdatedToast, callback }: {
    modalClass: string, 
    userType: 'seller' | 'buyer',
    displayUpdatedToast: boolean,
    callback: (modalContext: BModalComponent) => void
}) {
    const openModal = document.querySelectorAll(`.${modalClass}`);
    if (openModal.length) {
        const modalContext = getVueContextFromElement(openModal[0]);
        if (displayUpdatedToast) {
            displayUpdatedOfferToast({
                callback: () => callback(modalContext),
                otherUser: userType == 'seller' ? 'buyer' : 'seller'
            });
        } else { 
            callback(modalContext);
        }
    }
}

export function updateMarketplaceNegotiationModalWithCurrentOffer({ currentOffer, userType, modalContext}: {
    currentOffer: marketplaceCurrentOfferDTO, 
    userType: 'seller' | 'buyer',
    modalContext: BModalComponent,
}) {
    if (!modalContext) {
        return;
    }
    modalContext.props = {
        ...modalContext.props,
        negotiationStatus: isMarketplaceOfferPending(currentOffer.offerStatusId, userType) ? 'in progress' : 'rejected',
        buyerOfferAmount: currentOffer.buyerOfferAmount,
        sellerOfferAmount: currentOffer.sellerOfferAmount,
        displayMessage: currentOffer.mostRecentMessage,
        allMessages: currentOffer.messages,
    }
    modalContext.$forceUpdate();
}

export function displayUpdatedOfferToast({ callback, otherUser }: {
    callback: Function, 
    otherUser: 'seller' | 'buyer', 
}) {
    openToast('is-success', `The ${otherUser} has responded to your offer. Updating...`, 3000);
    window.setTimeout(() => {
        openToast('is-success', `Displaying updated ${otherUser} offer`);
        callback();
    }, 3000);
}

// Use this to determine whether or not to open the MarketplaceOfferModal
export function shouldOpenMarketplaceOfferModal({ offerExpirationDate, sellerOfferAmount, buyerOfferAmount, sellerPersonId }: { offerExpirationDate?: string | Date, sellerOfferAmount?: number, buyerOfferAmount?: number, sellerPersonId: number }): boolean {
  if (dateHasPassed(offerExpirationDate)) {
    return true;
  }
  
  const personId: number = parseInt(store.getters.getPersonId);

  const userIsVehicleSeller = sellerPersonId === personId;
  
  if ((!sellerOfferAmount && !userIsVehicleSeller) || (!buyerOfferAmount && userIsVehicleSeller)) {
    return true;
  }
  
  return false;
}
