var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bid-info-card",class:{ 
        'focused': _vm.isFocused,
        'highlight': _vm.isHighlighted, 
        'my-bid': _vm.isMyBid, 
        'reservePrice': _vm.isReserveMet,
        'highestBid': _vm.isHighestBid,
        [`buyer-${_vm.bid.personId}`]: true,
    }},[_c('div',{staticClass:"bid-and-bidder"},[_c('div',{staticClass:"bid-and-flags flex-row align-center"},[_c('span',{staticClass:"bid-amount is-poppins"},[_vm._v(_vm._s(_vm.toCurrency(_vm.bid.amount)))]),_vm._t("flag",function(){return [(_vm.flag)?_c('span',_vm._b({staticClass:"flag"},'span',_vm.$attrs.flagAttrs,false),[_vm._v(_vm._s(_vm.flag))]):_vm._e()]})],2),_c('div',{staticClass:"bidder"},[_c('div',[_c('p',{staticClass:"store-name is-poppins"},[_vm._v(_vm._s(_vm.bid.storeName))]),_c('p',{staticClass:"buyer-name"},[_vm._v(_vm._s(_vm.bid.buyerName))]),(_vm.bid.phoneNumber)?_c('p',{staticClass:"buyer-phone"},[_vm._v(_vm._s(_vm.formatPhoneNumber(_vm.bid.phoneNumber)))]):_vm._e()]),(_vm.bid.premiumStatus == 'PowerBuyer')?_c('div',{staticClass:"badge"},[_c('ThePowerUserIcon',_vm._b({},'ThePowerUserIcon',{ userType: 'buyer' },false))],1):_vm._e()])]),_c('div',{staticClass:"badge-and-date"},[_c('div',{staticClass:"time-date text-right"},[_c('p',{staticClass:"time bold"},[_vm._v(_vm._s(_vm.time))]),_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.date))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }