
import { defineComponent, PropType } from 'vue';
import { VehicleStatus } from '../../types';
import { openModal } from '../../utils';

import AppButton from '../AppButton.vue';
import OrderSummaryPdf from '../../views/OrderSummaryPdf.vue';
import TheCheckoutModalFacilitatingAuction from '../TheCheckoutModalFacilitatingAuction.vue';
import TheCheckoutModalRegularListing from '../TheCheckoutModalRegularListing.vue';
import { useUserRole } from '../../composables';

export default defineComponent({
    inheritAttrs: false,
    name: 'TheCheckoutButton',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vehicleStatus: {
            type: String as PropType<VehicleStatus>,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: false,
        },
        facilitatingAuctionStoreName: {
            type: String,
            required: false,
        },
        vehicleName: {
            type: String,
            required: false,
        },
        vehiclePrice: {
            type: Number,
            required: false,
        },
        useOrderSummary: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppButton,
    },
    emits: ['checkoutCompleted'],
    setup(props, { emit }) {
        const { isUserAdmin, isUserDsr } = useUserRole();

        function openCheckoutModal() {
            if (props.facilitatingAuctionStoreName) {
                openFacilitatingAuctionCheckoutModal();
            } else if (props.isReadOnly || ['MarketplaceSold', 'MarketplaceCompleted', 'Sold', 'Completed', 'SecondChanceSold', 'SecondChanceCompleted'].includes(props.vehicleStatus)) {
                openOrderSummaryModal();
            } else {
                openRegularCheckoutModal();
            }
        }

        function openOrderSummaryModal() {
            let orderSummaryMessage;
            if ((isUserAdmin.value || isUserDsr.value) && ['Checkout', 'MarketplaceCheckout', 'SecondChanceCheckout'].includes(props.vehicleStatus)) {
                orderSummaryMessage = 'This vehicle has not been checked out. Values may not be final.'
            }
            openModal({
                component: OrderSummaryPdf,
                props: { 
                    vehicleListingId: props.vehicleListingId,
                    orderSummaryMessage
                },
                hasModalCard: false,
            });
        }

        function openFacilitatingAuctionCheckoutModal() {
            openModal({
                component: TheCheckoutModalFacilitatingAuction,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    marketplaceListingId: props.marketplaceListingId,
                    vehicleName: props.vehicleName,
                    storeName: props.facilitatingAuctionStoreName,
                    vehiclePrice: props.vehiclePrice,
                },
                events: {
                    submitted: () => emit('checkoutCompleted'),
                }
            });
        }

        function openRegularCheckoutModal() {
            openModal({
                component: TheCheckoutModalRegularListing,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    marketplaceListingId: props.marketplaceListingId,
                    vehicleStatus: props.vehicleStatus, 
                    vehiclePrice: props.vehiclePrice,
                    vehicleName: props.vehicleName,
                },
                events: {
                    submitted: () => emit('checkoutCompleted'),
                }
            });
        }

        return {
            openCheckoutModal,
        }
    }
});
