var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TheNegotiateButton',_vm._b({on:{"click":_vm.openNegotiationModal}},'TheNegotiateButton',{
        isPending: _vm.isOfferPending,
        buttonLabel: _vm.adminButtonLabel,
        disabled: _vm.loadingNegotiationHistory || _vm.isOfferPending,
        type: 'is-primary',
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }