import { render, staticRenderFns } from "./AppStatusIcon.vue?vue&type=template&id=07052091&scoped=true&"
import script from "./AppStatusIcon.vue?vue&type=script&lang=js&"
export * from "./AppStatusIcon.vue?vue&type=script&lang=js&"
import style0 from "./AppStatusIcon.vue?vue&type=style&index=0&id=07052091&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07052091",
  null
  
)

export default component.exports