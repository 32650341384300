import { BidHistoryBid } from "@/types";
import { computed, reactive } from "vue";

export type BidHistoryHighlight = {
    title: 'Highest Bid' | 'Highest Proxy' | 'Reserve Price' | 'Buy-It-Now Price',
    value: number, 
    flag?: string,
}

export function useBidHistoryHighlights({ bidHistory, highestBidAmount, highestProxyBidAmount, reservePrice, reserveMet, buyItNowPrice,  }: {
    bidHistory: BidHistoryBid[],
    highestBidAmount?: number,
    highestProxyBidAmount?: number,
    buyItNowPrice?: number,
    reservePrice: number,
    reserveMet?: boolean,
}) {
    const highlights = reactive({
        highestBid: {
            title: 'Highest Bid',
            value: highestBidAmount,
        },
        highestProxy: {
            title: 'Highest Proxy',
            value: highestProxyBidAmount,
        },
        reservePrice: {
            title: 'Reserve Price',
            value: reservePrice,
            flag: reserveMet ? 'met' : '',
        },
        buyItNowPrice: {
            title: 'Buy-It-Now Price',
            value: buyItNowPrice,
        }
    });

    return {
        highlights,
    }
}

export function useBidHistoryBuyers({ bidHistory }: {
    bidHistory: BidHistoryBid[],
}) {
    const uniqueBuyers: { name: string, storeName: string, personId: number }[] = [];
    const uniqueStores: string[] = [];
    bidHistory.forEach(bid => {
        if (!uniqueBuyers.some(buyer => buyer.name == bid.buyerName)) {
            uniqueBuyers.push({
                name: bid.buyerName,
                storeName: bid.storeName,
                personId: bid.personId,
            });
        }
        if (!uniqueStores.includes(bid.storeName)) {
            uniqueStores.push(bid.storeName)
        }
    });
    
    return {
        buyers: uniqueBuyers,
        stores: uniqueStores,
    }
}