import { APIConfig, TimeDTO } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from "@/utils";
import { GET } from ".";

export async function getArbitrationRateBySellerStoreId(sellerStoreId: number): Promise<number | undefined> {
    return await GET(`/arbitration/arbitrationRate/${sellerStoreId}`)
        .then(res => res.data)
        .catch(error => { throw error });
}

export async function getTimeToTitleBySellerStoreId(sellerPersonId: number | undefined): Promise<TimeDTO | undefined> {
    return await GET(`/vehicles/averageTimeToTitle/${sellerPersonId}`)
        .then(res => res.data)
        .catch(error => { throw error });
}

export async function getSellRateBySellerStoreId(sellerPersonId: number | undefined): Promise<number | undefined> {
    return await GET(`/orders/sellRate/${sellerPersonId}`)
        .then(res => res.data)
        .catch(error => { throw error });
}

export async function didSellerAcceptTermsAndConditions(vehicleListingId: number, config: APIConfig={}): Promise<boolean> {
    return await GET(`/vehicles/getTermsAcceptedBySellerForVehicleListing/${vehicleListingId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to check terms & conditions acceptance',
                message: `We encountered an error while confirming whether the seller has accepted terms & conditions`,
                error,
            });
        });
}
