
import { computed, defineComponent, PropType } from 'vue';
import { useFetchNegotiationHistory, useLoggedInUser, useUserRole } from '../composables';
import { Negotiation } from '../types';
import { openModal } from '../utils';

import TheNegotiationModalAuction from './TheNegotiationModalAuction.vue';
import TheNegotiateButton from './TheNegotiateButton.vue';

export default defineComponent({
    name: 'TheNegotiateButtonAuction',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        vin: {
            type: String,
            required: true,
        },
        negotiationHistory: {
            type: Array as PropType<Negotiation[]>, 
            required: false,
        }
    },
    components: {
        TheNegotiateButton,
    },
    setup(props, { emit, attrs }) {
        // get negotiations if not provided in props
        const { negotiationHistory, loadingNegotiationHistory } = useFetchNegotiationHistory({ vehicleListingId: props.vehicleListingId, negotiationHistory: props.negotiationHistory});
        const currentNegotiation = computed(() => negotiationHistory.value ? negotiationHistory.value[negotiationHistory.value?.length-1] : undefined);
        const negotiatingUser = computed(() => {
            if (!currentNegotiation.value) {
                return undefined;
            }
            return currentNegotiation.value.onBuyer ? 'buyer' : 'seller';
        });

        // get button label information

        const { isUserAdmin, isUserDsr } = useUserRole();
        const { loggedInUserPersonId } = useLoggedInUser();
        const adminButtonLabel = computed(() => {
            if ((!isUserAdmin.value && !isUserDsr.value) || !negotiatingUser.value) {
                return undefined;
            }
            return `Negotiate for ${negotiatingUser.value}`;
        });
        
        const isOfferPending = computed(() => {
            if (isUserAdmin.value) {
                return false;
            }
            if (loggedInUserPersonId.value == currentNegotiation.value?.buyerId) {
                return negotiatingUser.value == 'seller';
            }
            if (loggedInUserPersonId.value == currentNegotiation.value?.sellerId) {
                return negotiatingUser.value == 'buyer';
            }
            return true;
        });

        function openNegotiationModal() {
            openModal({
                component: TheNegotiationModalAuction,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    negotiatingUser: negotiatingUser.value,
                    vin: props.vin,
                    sellerOfferAmount: currentNegotiation.value.lowestSellerOffer,
                    buyerOfferAmount: currentNegotiation.value.highestBuyerOffer,
                    negotiationStatus: currentNegotiation.value.status == 'rejected' ? 'rejected' : 'in progress',
                    displayRejectedMessage: true,
                    sellerPersonId: parseInt(currentNegotiation.value.sellerId),
                    buyerPersonId: parseInt(currentNegotiation.value.buyerId),
                }
            });
        }
        return {
            adminButtonLabel, 
            isOfferPending,
            negotiationHistory,
            loadingNegotiationHistory,
            openNegotiationModal,
        }
    },
});
