<template>
    <Modal
        v-bind="{
            isLoading: loadingSaveChanges,
            disableConfirm: disableSubmit,
            customLoading: true,
        }"
        @close="$emit('close')"
        @confirm="shouldSubmit = true"
        :style="windowWidth > 500 ? 'min-width: 500px;' : 'min-width: fit-content;'"
        class="carmigo-modal"
    >
        <template #header>
            Edit Auction
        </template>

        <template #body>
            <TheEditAuctionInput 
                v-bind="{
                    triggerSubmit: shouldSubmit,
                    ...$props,
                }"
                @validated="disableSubmit = $event"
                @toggleLoading="loadingSaveChanges = $event"
                @savedUpdates="$emit('close')"
            />
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useBreakpoint } from '../composables';
import { EditAuctionProps } from '../types';

import Modal from './Modal.vue';
import TheEditAuctionInput from './TheEditAuctionInput.vue';

export default defineComponent({
    name: 'TheEditAuctionModal',
    props: {
        ...EditAuctionProps,
        numBids: {
            type: Number,
            default: 0,
        },
    },
    components: {
        Modal,
        TheEditAuctionInput,
    },
    setup() {
        const shouldSubmit = ref(false);
        const disableSubmit = ref(true);
        const loadingSaveChanges = ref(false);

        const { windowWidth } = useBreakpoint();

        return {
            shouldSubmit,
            disableSubmit,
            loadingSaveChanges,
            windowWidth,
        }
    }
});
</script>