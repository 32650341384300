<template>
  <b-tooltip
    v-bind="{
      label: tooltip,
      position: 'is-bottom',
      ...$attrs.tooltipAttrs,
    }"
    class="status-tooltip status-icon-container"
    :class="$attrs.class"
  >
    <b-icon 
      v-bind="{
        icon,
        size: 'is-medium',
        ...$attrs,
      }"
    >
    </b-icon>
  </b-tooltip>
</template>

<script>
export default ({
  name: 'AppStatusIcon',
  props: {
    tooltip: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    }
  },
});
</script>

<style scoped lang="postcss">
.status-icon-container {
  width: 100%;
  margin: 0 .15rem;
  padding: .25rem 0;
  border-radius: .25rem;
  min-width: 55px;

  &.is-primary {
    background-color: var(--primary-light);
    color: var(--primary) !important;
    & .icon {
      color: var(--primary);
    }
  }

  &.is-yellow {
    color: var(--dark);
    background-color: rgba(245, 185, 66, .75);
    & .icon {
      color: var(--dark);
    }
  }

  &.is-grey-light {
    background-color: var(--grey-light);
    color: var(--dark);
    & .icon {
      color: var(--dark);
    }
  }
}

.status-tooltip {
  text-transform: none;
}

:deep(.tooltip-trigger) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
