var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"listing-stats flex-row justify-space-between align-center width-100"},[_c('div',{staticClass:"listing-counts flex-row align-center",class:{'width-100': !_vm.price && !_vm.highestBid?.amount}},[_vm._t("left",function(){return [_c('TheListingDetailsViewsAndCounts',_vm._b({staticClass:"flex-row"},'TheListingDetailsViewsAndCounts',{
                    viewCount: _vm.views,
                    offerCount: _vm.offers,
                    bidCount: _vm.bids,
                },false)),_vm._t("timer",function(){return [(_vm.date)?_c('div',{staticClass:"listing-timer flex-row align-center"},[_c('b-icon',{staticClass:"has-text-primary",attrs:{"icon":"timer-sand"}}),_c('CountdownTimer',_vm._b({},'CountdownTimer',{
                            date1: _vm.date,
                            checkTimeRemaining: true,
                            shouldFetchServerTime: false,
                            color: 'has-text-white',
                        },false))],1):_vm._e()]})]})],2),_vm._t("right",function(){return [(_vm.price || _vm.highestBid?.amount)?_c('div',{staticClass:"current-price flex-row align-center"},[_c('p',{staticClass:"price-label mr-3"},[_vm._v(_vm._s(_vm.priceLabel))]),(_vm.price)?_c('p',{staticClass:"price-amount is-poppins"},[_vm._v(_vm._s(_vm.toCurrency(_vm.price)))]):_c('BidCurrent',_vm._b({},'BidCurrent',{
                    bidAmount: _vm.highestBid?.amount,
                    highestBid: _vm.highestBid,
                    orderBuyerPersonId: _vm.orderBuyerPersonId,
                },false))],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }