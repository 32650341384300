import { APIConfig, BuyerMarketplaceOffer } from "@/types";
import { applyAPIConfigOnError, applyAPIConfigOnSuccess, openErrorDialog } from "@/utils";
import { GET } from ".";

export async function getSecondChanceDetails(vehicleListingId: number, config: APIConfig={}) {
    return await GET(`/marketplace/${vehicleListingId}/secondChance`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
            openErrorDialog({
                title: 'Failed to fetch Second Chance details',
                message: `We encountered an error while fetching the Second Price details for listing ${vehicleListingId}.`,
                error,
            });
        });
}

export async function getSecondChanceListingForBuyerDashboard(marketplaceListingId: number, config: APIConfig={}): Promise<BuyerMarketplaceOffer | null> {
    return await GET(`/marketplace/getSecondChanceListing/buyerDashboard/${marketplaceListingId}`)
        .then(res => {
            applyAPIConfigOnSuccess(res.data, config);
            return res.data;
        }).catch(error => {
            applyAPIConfigOnError(error, config);
        });
}