var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[_c('h3',[_vm._v("Starting Price")]),(_vm.auctionHasBid)?_c('p',{staticClass:"has-text-grey-light is-size-6"},[_vm._v(" Cannot be edited once the auction has a bid ")]):_vm._e(),_c('InputField',_vm._b({model:{value:(_vm.updatedAuction.startingPrice),callback:function ($$v) {_vm.$set(_vm.updatedAuction, "startingPrice", $$v)},expression:"updatedAuction.startingPrice"}},'InputField',{
                type: 'currency',
                useCustomStyles: false,
                disabled: _vm.auctionHasBid,
            },false))],1),_c('InputField',_vm._b({model:{value:(_vm.updatedAuction.reservePrice),callback:function ($$v) {_vm.$set(_vm.updatedAuction, "reservePrice", $$v)},expression:"updatedAuction.reservePrice"}},'InputField',{
            label: 'Reserve Price',
            type: 'currency',
            useCustomStyles: false,
        },false)),_c('div',{staticClass:"buy-it-now"},[_c('p',{staticClass:"bold"},[_vm._v(" Buy-It-Now Price"+_vm._s(_vm.updatedAuction.canBuyItNow ? '' : ' (disabled)')+" ")]),_c('div',{staticClass:"flex-row justify-space-between align-center"},[_c('InputField',_vm._b({staticClass:"width-80",on:{"invalidInput":function($event){return _vm.$emit('validated', true)}},model:{value:(_vm.updatedAuction.buyItNowPrice),callback:function ($$v) {_vm.$set(_vm.updatedAuction, "buyItNowPrice", $$v)},expression:"updatedAuction.buyItNowPrice"}},'InputField',{
                    type: 'currency',
                    useCustomStyles: false,
                    disabled: !_vm.updatedAuction.canBuyItNow,
                    customValidator: _vm.validateBuyItNowPrice,
                    customInvalidMessage: _vm.buyItNowPriceInvalidMessage,
                    shouldValidateCustom: true,
                },false)),_c('AppSwitch',{staticClass:"ml-2",on:{"input":function($event){return _vm.validateBuyItNowPrice(_vm.updatedAuction.buyItNowPrice)}},model:{value:(_vm.updatedAuction.canBuyItNow),callback:function ($$v) {_vm.$set(_vm.updatedAuction, "canBuyItNow", $$v)},expression:"updatedAuction.canBuyItNow"}})],1)]),_c('div',[_c('h3',[_vm._v("Start Time")]),(_vm.auctionHasStarted)?_c('p',{staticClass:"has-text-grey-light is-size-6"},[_vm._v(" Cannot be edited once the auction has started ")]):_vm._e(),_c('ScheduleAuctionDateTimePickers',_vm._b({on:{"startTime":function($event){_vm.updatedAuction.auctionStart = $event; _vm.toggleAutofillEndTime()},"endTime":function($event){_vm.updatedAuction.auctionEnd = $event}}},'ScheduleAuctionDateTimePickers',{
                initialStartTime: _vm.auctionStart,
                initialEndTime: _vm.auctionEnd,
                autofillEndTime: _vm.autofillEndTime,
                datetimepickerAttrs: {
                    appendToBody: true,
                },
                startTimeAttrs: {
                    disabled: _vm.auctionHasStarted || _vm.isUserDsr,
                },
                endTimeAttrs: {
                    disabled: _vm.isUserDsr,
                },
                position: 'is-top-right',
            },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }