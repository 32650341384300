var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppListingDetails',_vm._b({scopedSlots:_vm._u([{key:"listingStats",fn:function(){return [_c('TheListingDetailsListingStats',_vm._b({},'TheListingDetailsListingStats',{
                price: _vm.order?.vehiclePrice ?? _vm.listing.auction.reservePrice,
                priceLabel: _vm.getPriceLabel,
                views: _vm.listingDetails.numViews,
                offers: _vm.listingDetails.numOffers,
            },false))]},proxy:true},{key:"listingButtons",fn:function(){return [_c('div',{staticClass:"marketplace-buttons width-100"},[((_vm.isMyListing || _vm.isUserAdmin || _vm.isUserDsr) && _vm.showOfferButton)?_c('TheMarketplaceManageOffersButton',_vm._b({},'TheMarketplaceManageOffersButton',{
                    marketplaceListingId: _vm.marketplaceListingId,
                    vehicleListingId: _vm.listing.id,
                    marketplaceOfferCount: _vm.marketplaceOffers?.length,
                    size: 'is-medium',
                    isSecondChance: _vm.listing.isSecondChance,
                },false)):(_vm.showOfferButton)?_c('TheMarketplaceListingButtons',_vm._b({on:{"offerMade":_vm.getUpdatedMarketplaceListingDetails}},'TheMarketplaceListingButtons',{
                    marketplaceListingId: _vm.marketplaceListingId,
                    vehicleListingId: _vm.listing.id,
                    buyItNowPrice: _vm.listing.auction.buyItNowPrice,
                    reservePrice: _vm.listing.auction.reservePrice,
                    sellerPersonId: _vm.listing.userProfile.id,
                    currentOffer: _vm.listingDetails.currentOffer,
                    disabled: _vm.isCheckoutOrLater,
                    canBuyItNow: _vm.listing.marketplaceCanBuyItNow,
                    sellerStoreId: _vm.listing.sellerStore?.id,
                    size: 'is-medium',
                    buyItNowButtonAttrs: {
                        label: _vm.listing.isSecondChance ? 'Buy at reserve' : 'Buy It Now',
                    },
                    isSecondChance: true,
                },false)):(_vm.isCheckoutStatus && _vm.purchasedVehicle)?_c('TheCheckoutButton',_vm._b({},'TheCheckoutButton',{
                    vehicleListingId: _vm.listing.id,
                    marketplaceListingId: _vm.marketplaceListingId,
                    vehicleStatus: _vm.listingDetails.status,
                    vehicleName: `${_vm.listing.year} ${_vm.listing.brand} ${_vm.listing.model}`,
                    vehiclePrice: _vm.order?.vehiclePrice,
                    facilitatingAuctionStoreName: _vm.listing.facilitatingAuction?.name,
                    size: 'is-medium',
                    outlined: false,
                },false)):(_vm.purchasedVehicle && _vm.isVehicleSold(_vm.listingDetails.status))?_c('div',{staticClass:"buttons width-100"},[_c('ViewOrderSummaryButton',_vm._b({},'ViewOrderSummaryButton',{
                        vehicleListingId: _vm.listing.id,
                        vehicleStatus: _vm.listingDetails.status,
                        vehicleName: `${_vm.listing.year} ${_vm.listing.brand} ${_vm.listing.model}`,
                        size: 'is-medium',
                        outlined: false,
                    },false)),(!_vm.listing.facilitatingAuction && _vm.arbitrationDetails)?_c('TheOpenArbitrationClaimButton',_vm._b({},'TheOpenArbitrationClaimButton',{
                        vehicleListingId: _vm.listing.id,
                        ..._vm.arbitrationDetails,
                        hasOpenedClaim: Boolean(_vm.arbitrationDetails.openedArbitrationClaimId),
                        size: 'is-medium',
                    },false)):_vm._e()],1):_vm._e()],1)]},proxy:true}])},'AppListingDetails',{ 
        listing: _vm.listing,
        vehiclePhotos: _vm.vehiclePhotos,
        announcements: _vm.announcements,
        loadingAnnouncements: _vm.loadingAnnouncements,
        bookoutOptions: _vm.bookoutOptions,
        loadingBookoutOptions: _vm.loadingBookoutOptions,
        tireTread: _vm.tireTread,
        loadingTireTread: _vm.loadingTireTread,
        marketData: _vm.marketData,
        order: _vm.order,
        loadingOrder: _vm.loadingOrder,
        loadingMarketData: _vm.loadingMarketData,
        averageMarketDataCost: _vm.averageMarketDataCost,
        arbitrationDetails: _vm.arbitrationDetails,
        ..._vm.$attrs,
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }