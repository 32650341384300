
import { PropType, computed, defineComponent, onMounted, ref } from "vue";
import { useDealerMarketplaceOfferListener } from '@/composables';
import store from '@/vuex';
import type { MarketplaceListingOfferDataDTOFormatted } from '@/types';
import { GET } from "../api";
import { dateHasPassed, isMarketplaceOfferPending, openErrorDialog, openModal, selectMarketplaceOfferCountdownColor, shouldOpenMarketplaceOfferModal, toCurrency, toCurrencyShowDashesIfNull, updateMarketplaceModalWithIncomingOffer, updateMarketplaceNegotiationModalWithCurrentOffer } from "../utils";

import AppButton from './AppButton.vue';
import AppLoadingSkeleton from './AppLoadingSkeleton.vue';
import TableSpecialColumns from "./Tables/TableSpecialColumns.vue";
import TheNegotiateButtonMarketplace from './TheNegotiateButtonMarketplace.vue';
import TheNegotiationModalMarketplace from "./TheNegotiationModalMarketplace.vue";
import TheMarketplaceListingOfferModal from './TheMarketplaceListingOfferModal.vue';
import CountdownTimer from './CountdownTimer.vue';

export default defineComponent({
    name: 'TheMarketplaceListingOffersTable',
    props: {
        vehicleListingId: {
            type: Number,
            required: true,
        },
        marketplaceListingId: {
            type: Number,
            required: true,
        },
        offers: {
            type: Array as PropType<MarketplaceListingOfferDataDTOFormatted[]>,
            required: false,
            default: () => [],
        },
        isListingLive: {
            type: Boolean,
            default: true,
        },
        showNegotiationActionsColumn: {
            type: Boolean,
            default: true,
        },
        isSecondChance: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton,
        AppLoadingSkeleton,
        TableSpecialColumns,
        TheNegotiateButtonMarketplace,
        CountdownTimer,
    },
    emits: ['offersLoaded'],
    setup(props, { emit }) {
        const sellerPersonId = parseInt(store.getters.getPersonId);

        function offerExpirationDateHasExpired(rowData) {
            const {offerExpirationDate} = rowData;
            return dateHasPassed(offerExpirationDate);
        }
        function countdownColorSelector(rowData) {
            return selectMarketplaceOfferCountdownColor({
                sellerPersonId,
                mostRecentOffererTypeId: rowData.mostRecentOffererTypeId,
            });
        }

        function openMarketplaceNegotiationModal(rowData) {
            openModal({
                component: TheNegotiationModalMarketplace,
                customClass: `marketplace-negotiate-modal-${rowData.marketplaceOfferId}`,
                props: {
                    vehicleListingId: props.vehicleListingId,
                    marketplaceListingId: props.marketplaceListingId,
                    marketplaceOfferId: rowData.marketplaceOfferId,
                    negotiationStatus: isMarketplaceOfferPending(rowData.offerStatusId, 'seller') ? 'in progress' : 'rejected',
                    negotiatingUser: 'seller',
                    buyerOfferAmount: rowData.buyerOfferAmount,
                    sellerOfferAmount: rowData.sellerOfferAmount,
                    buyItNowPrice: rowData.buyItNowPrice,
                    reservePrice: rowData.reservePrice,
                    displayMessage: rowData.mostRecentMessage,
                    offerExpirationDate: rowData.offerExpirationDate,
                    isSecondChance: props.isSecondChance,
                },
            });
        }

        function openMarketplaceOfferModal(rowData) {
            openModal({
                component: TheMarketplaceListingOfferModal,
                customClass: `marketplace-offer-modal-${props.marketplaceListingId}`,
                props: {
                    marketplaceListingId: props.marketplaceListingId,
                    vehicleListingId: props.vehicleListingId,
                    reservePrice: rowData.reservePrice,
                    buyItNowPrice: rowData.buyItNowPrice,
                    sellerPersonId,
                    stores: [],
                    initialStoreId: undefined,
                    currentOffer: rowData.offerExpirationDate 
                        ? {
                            marketplaceOfferId: rowData.marketplaceOfferId,
                            buyerOfferAmount: rowData.buyerOfferAmount,
                            offerExpirationDate: rowData.offerExpirationDate,
                            mostRecentOffererTypeId: rowData.mostRecentOffererTypeId,
                            sellerOfferAmount: rowData.sellerOfferAmount,
                          }
                        : undefined,
                }, 
            });
        }

        function openMarketplaceModal(rowData) {
            if (shouldOpenMarketplaceOfferModal({
                offerExpirationDate: rowData.offerExpirationDate,
                sellerOfferAmount: rowData.sellerOfferAmount,
                buyerOfferAmount: rowData.buyerOfferAmount,
                sellerPersonId,
            })) {
                    openMarketplaceOfferModal(rowData);
            } else {
                openMarketplaceNegotiationModal(rowData);
            }
        }


        // Table data
        const listingOffers = ref(props.offers);
        onMounted(() => {
            if (props.offers.length === 0 && props.isListingLive) {
                getMarketplaceOffersByListingId(props.marketplaceListingId)
            }
        });

        const loadingTableData = ref(false);

        async function getMarketplaceOffersByListingId(marketplaceListingId: number) {
            loadingTableData.value = true;
            try {
                const { data } = await GET<MarketplaceListingOfferDataDTOFormatted[]>(`/marketplace/getMarketplaceListing/marketplaceListingOffers/${marketplaceListingId}`);
                listingOffers.value = data;
                emit('offersLoaded', data);
            } catch (error) {
                openErrorDialog({
                    title: 'Could not fetch offers',
                    message: `We encountered an error while fetching the offers for marketplace listing ${marketplaceListingId}`,
                    error,
                });
            }
            loadingTableData.value = false;
        }

        // eslint-disable-next-line arrow-body-style
        const offerTableData = computed(() => {
            offersTableKey.value;
            // eslint-disable-next-line arrow-body-style
            return listingOffers.value.map((row) => {
                return {
                    ...row,
                    buyerName: `${row.buyer.firstName} ${row.buyer.lastName}`,
                    buyerStoreName: row.buyer.storeName,
                };
            });
        });

        const tableColumns = computed(() => {
            const standardAttrs = {
                centered: true,
                sortable: true,
            }
            return [
                {
                    field: 'sellerOfferAmount',
                    label: 'Your Offer',
                    attrs: {
                        ...standardAttrs,
                        filter: toCurrencyShowDashesIfNull,
                        class: 'bold',
                    },
                },
                {
                    field: 'buyerOfferAmount',
                    label: 'Their Offer',
                    attrs: {
                        ...standardAttrs,
                        filter: toCurrencyShowDashesIfNull,
                        class: 'bold',
                    }
                },
                {
                    field: 'buyerName',
                    label: 'Buyer',
                    attrs: standardAttrs,
                },
                {
                    field: 'buyerStoreName',
                    label: 'Store',
                    attrs: {
                        ...standardAttrs,
                        width: '250',
                    },
                },
            ];
        });

        // RDB Updates
        useDealerMarketplaceOfferListener({ 
            vehicleListingId: props.vehicleListingId,
            onOfferUpdate: getUpdatedOffersAndUpdateTableRows,
        });

        async function getUpdatedOffersAndUpdateTableRows({ updatedMarketplaceOfferIds }) {
            const updatedOffers = await Promise.all(
                updatedMarketplaceOfferIds.map(offerId => GET<MarketplaceListingOfferDataDTOFormatted>(`/marketplace/getMarketplaceListing/marketplaceListingOffer/${offerId}`)
                    .then(res => res.data)
            ));

            updatedOffers.forEach(offer => {
                updateTableRow(offer);
                updateMarketplaceModalWithIncomingOffer({
                    modalClass: `marketplace-negotiate-modal-${offer.marketplaceOfferId}`,
                    userType: 'seller',
                    displayUpdatedToast: true,
                    callback: (modalContext) => {
                        updateMarketplaceNegotiationModalWithCurrentOffer({
                            currentOffer: offer,
                            userType: 'seller',
                            modalContext,
                        });
                    }
                });
            });
        }

        const offersTableKey = ref(0);
        function updateTableRow(updatedOffer) {
            const rowIdx = listingOffers.value.findIndex(item => item.marketplaceOfferId == updatedOffer.marketplaceOfferId);
            if (rowIdx >= 0) {
                listingOffers.value[rowIdx] = {
                    ...listingOffers.value[rowIdx],
                    ...updatedOffer,
                }
            } else {
                listingOffers.value.push(updatedOffer);
            }
            offersTableKey.value++;
        }

        return {
            openMarketplaceNegotiationModal,
            tableColumns,
            loadingTableData,
            offerTableData,
            offerExpirationDateHasExpired,
            countdownColorSelector,
            openMarketplaceModal,
        }
    }
})
