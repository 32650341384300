import {
    computed,
    onMounted,
    ref,
    unref,
    watch,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import { GET, getStoreNameByStoreId, getStoresBySellerPersonId } from '@/api';
import { FilterFieldOptionsSchema, FilterSchema, FixMe, MaybeRef, storeLite } from '@/types';
import { getPaginationOffset, openDialogAlert, redirectTo } from '@/utils';
import debounce from 'lodash/debounce';
import {
    UseFetchOptionsWithInitialData,
    useCancelToken,
    useFetch,
} from '@/composables';
import { useUserRole } from './user';
import { useStore } from './useStore';

type Store = Pick<storeLite, 'id' | 'name'>

export function useStoreSelector() {
    const route = useRoute();
    const store = useStore();
    const { isUserAdmin, isUserDsr } = useUserRole();
    const { storeId } = route.query;

    const isStoreListLoading = ref(false);
    const isFetchingStoreListOnType = ref(false);
    const storeInput = ref('');
    const stores = ref<storeLite[]>([]);
    const selectedStore = ref<storeLite | null>(null);

    const filteredStoreList = computed(() => {
        if (!stores.value) {
            return [];
        }

        return stores.value.filter((option) => option
            .name
            .toString()
            .toLowerCase()
            .indexOf(storeInput.value.toLowerCase()) >= 0);
    });

    const shouldShowStoreSelector = computed(() => isStoreListLoading.value || (isUserAdmin.value || isUserDsr.value || stores.value.length > 1));

    onMounted(async () => {
        isStoreListLoading.value = true;

        if (!(isUserAdmin.value || isUserDsr.value)) {
            stores.value = await getStoresBySellerPersonId(store.getters.getPersonId);
        }

        const [firstStore] = stores.value;

        if (storeId) {
            // If store id provided in route params, get the store name and update selectedStore so that tables are refreshed
            let storeName = stores.value[0]?.name;
            if (isUserAdmin.value || isUserDsr.value) {
                const response = await getStoreNameByStoreId(Number(storeId));

                if (response) {
                    storeName = response;
                }
            } else {
                const foundStore = stores.value.find((i: Store) => Number(i.id) === Number(storeId));
                if (foundStore) {
                    storeName = foundStore.name;
                }
            }

            if (selectedStore.value?.id !== storeId) {
                // @ts-expect-error: TODO: Missing properties
                selectedStore.value = {
                    id: storeId as string,
                    name: storeName,
                };
            }

            storeInput.value = storeName;
        } else if (firstStore) {
            // If no storeId is provided in the route parameters,
            // use the first store from the wholesaler's store list in local storage.
            selectedStore.value = firstStore;
            storeInput.value = firstStore?.name ?? '';
            redirectTo({
                name: 'Turn',
                query: {
                    storeId: firstStore.id,
                },
                preserveQuery: true,
            });
        }

        isStoreListLoading.value = false;
    });

    const handleStoreTyping = debounce(async (payload: string) => {
        if (!(isUserAdmin.value || isUserDsr.value) || !payload) {
            return;
        }

        isFetchingStoreListOnType.value = true;
        try {
            const { data } = await GET(`/company/getStoresByName?storeName=${payload}`);
            stores.value = data;
        } catch {
            openDialogAlert('Error fetching stores', 'There was a problem fetching stores. If the problem persists, contact support.');
        } finally {
            isFetchingStoreListOnType.value = false;
        }
    }, 250);

    function handleStoreSelect(selected: storeLite) {
        selectedStore.value = selected;

        if (!selected || selected.id === route.query.storeId) {
            return;
        }

        redirectTo({
            name: 'Turn',
            query: {
                page: '1',
                storeId: selected.id,
            },
            preserveQuery: true,
            omitQuery: ['sortBy', 'order'],
        });
    }

    return {
        isStoreListLoading,
        stores,
        selectedStore,
        storeInput,
        handleStoreTyping,
        filteredStoreList,
        isFetchingStoreListOnType,
        handleStoreSelect,
        shouldShowStoreSelector,
    };
}

export type RouteQueryToDatabaseFieldMap = {
    storeId: string
    [key: string]: string
};

export type SortDirection = 'asc' | 'desc';

export function usePaginatedFetch<T>(url: Parameters<typeof useFetch>[0], options?: UseFetchOptionsWithInitialData<T> & {
    /**
     * Use this field as the default sort field if the route query does not have a `sortBy` field.
     */
    defaultSortField?: string;
    /**
     * Use this field as the default sort order if the route query does not have an `order` field.
     */
    defaultSortOrder?: MaybeRef<SortDirection>;
    /**
     * Map the route query fields to the database fields.
     */
    routeQueryToDatabaseFieldMap: RouteQueryToDatabaseFieldMap;
    additionalFilters?: FilterSchema[]
}) {
    const cancelToken = useCancelToken();
    const { execute, ...rest } = useFetch<T>(url, {
        method: 'PUT',
        immediate: false,
        resetOnExecute: true,
        cancelToken,
        ...options,
    } as FixMe);

    const route = useRoute();

    const filterFields = computed(() => {
        const sortBy = route.query.sortBy as string;
        const query = route.query.query as string;
        return {
            storeId: options?.routeQueryToDatabaseFieldMap.storeId,
            sortBy: options?.routeQueryToDatabaseFieldMap[sortBy] ?? sortBy ?? options?.defaultSortField,
            order: route.query?.order ?? unref(options?.defaultSortOrder) ?? 'desc',
            query: options?.routeQueryToDatabaseFieldMap[query] ?? query,
        };
    });

    function mutate(data: T) {
        rest.data.value = data;
    }

    watch(() => route.query, (queryParams) => {
        const {
            storeId,
            term,
            page = 1,
            order = filterFields.value.order,
            limit = 25,
        } = queryParams;

        if (!storeId) {
            return;
        }

        type FetchBody = {
            modifiers: Omit<Pick<FilterFieldOptionsSchema, 'modifiers'>['modifiers'], 'groupBy'> & {
                pagination?: {
                    paginationLimit: number,
                    paginationOffset: number,
                }
            }
        }

        const { query } = filterFields.value;
        const body: FetchBody = {
            modifiers: {
                filters: [
                    {
                        property: filterFields.value.storeId!,
                        comparator: '=',
                        values: storeId as string,
                    },
                    ...(query && term ? [{
                        property: filterFields.value.query as string,
                        comparator: query === 'vin' ? '=' : 'iLike',
                        values: (term as string).trim(),
                    }] : []) as FilterSchema[],
                    ...options.additionalFilters ?? [],
                ],
                ...(filterFields.value.sortBy ? {
                    orderBy: [{
                        property: filterFields.value.sortBy,
                        order,
                        nulls: 'last',
                    }],
                } : {}),
                pagination: {
                    paginationLimit: Number(limit),
                    paginationOffset: getPaginationOffset(Number(page), Number(limit)),
                },
            },
        };

        // Auctioning tab does not need pagination and sorting is handled by UI.
        if (route.query.tab === 'auctioning') {
            delete body.modifiers.pagination;
        }

        execute(body);
    }, {
        immediate: true,
    });

    return {
        execute,
        mutate,
        ...rest,
    };
}

/**
 * A composable that returns the default sort field and order based on the route query params if not empty, or the provided default values.
 */
export function useDefaultSortColumnAndOrder({
    sortBy,
    sortDirection,
}: {
    sortBy: string
    sortDirection: SortDirection
}) {
    const route = useRoute();
    const defaultSortField = (route.query.sortBy as string) ?? sortBy;
    const defaultSortDirection = (route.query.order as SortDirection) ?? sortDirection;

    return {
        defaultSortField,
        defaultSortDirection,
    };
}
