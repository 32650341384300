import { ToastProgrammatic, DialogProgrammatic, ModalProgrammatic, LoadingProgrammatic, SnackbarProgrammatic, NotificationProgrammatic } from 'buefy';
import type { BDialogConfig, BLoadingConfig, BModalConfig, BNoticeComponent, BNoticeConfig, BNotificationConfig, BSnackbarConfig } from 'buefy/types/components';
import type { CreateVehicleDTO, VehicleStatus } from '@/types';
import * as Sentry from '@sentry/vue';
import { database } from '@/firebase';
import store from '@/vuex';
import { Component } from 'vue';
import { getDifferenceBetweenDateValues } from './dateandTimeUtils';

export function openToast(type: string, message: string, duration: number | 'indefinite' = 5000) {
  if (type === 'is-danger') {
    Sentry.captureMessage(message, {
      level: 'error',
    });
  }

  return ToastProgrammatic.open({
    duration: duration === 'indefinite' ? undefined : duration,
    indefinite: duration === 'indefinite',
    message,
    type,
  });
}

export function closeToast(toast: BNoticeComponent | null | undefined) {
  if (toast) {
    toast.close();
  }
}

export function openDialogAlert(title: string, message: string, onConfirm?: () => void) {
    Sentry.captureMessage(message, {
        level: 'error',
    });

    return DialogProgrammatic.alert({
        title,
        message,
        type: 'is-danger',
        hasIcon: true,
        icon: 'close-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
        onConfirm,
    });
}

// TODO: replace openDialogAlert with this
export function openAlertDialog(config: {
  title: string,
  message: string,
  type?: string,
  icon?: string,
  onConfirm?: BDialogConfig['onConfirm'],
  confirmText?: string,
  error?: any
}) {
    if (config.error) {
        Sentry.captureException(config.error, {
            extra: {
                title: config.title,
                message: config.message,
            },
        });
    } else {
        Sentry.captureMessage(config.message, { level: 'error' });
    }

    return DialogProgrammatic.alert({
        ...config,
        type: config.type ?? 'is-danger',
        icon: config.icon ?? 'close-circle',
        ariaRole: 'alertdialog',
        ariaModal: true,
    });
}

export function openSnackbar(config: BSnackbarConfig) {
    return SnackbarProgrammatic.open(config);
}

export function openNotification(config: BNotificationConfig) {
    return NotificationProgrammatic.open(config);
}

export function openToastNotification(config: BNoticeConfig) {
    return ToastProgrammatic.open(config);
}

// TODO replace openDialogConfirm with this
export function openConfirmationDialog(config: {
  title: string,
  message: BDialogConfig['message'],
  onConfirm: BDialogConfig['onConfirm'],
  onCancel?: BDialogConfig['onCancel'],
  confirmText?: string,
  cancelText?: string,
  type?: BDialogConfig['type'],
}) {
  return DialogProgrammatic.confirm({
    ...config,
    confirmText: config.confirmText ?? 'Confirm',
    cancelText: config.cancelText ?? 'Cancel'
  });
}

export function openDialogConfirm(title: string, message: string, onConfirm: BDialogConfig['onConfirm'], onCancel?: BDialogConfig['onCancel']) {
  return DialogProgrammatic.confirm({
    title,
    message,
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    onConfirm,
    onCancel,
  });
}

export function openErrorRefreshAppDialog(onConfirm: BDialogConfig['onConfirm']=undefined, message:string| undefined=undefined) {
  const defaultMessage = 'There was an error loading the app. Please refresh the page.';

  Sentry.captureMessage(message || defaultMessage, {
    level: 'error',
  });

  return DialogProgrammatic.confirm({
    title: 'Something went wrong',
    message: message || defaultMessage,
    type: 'is-danger',
    hasIcon: true,
    icon: 'close-circle',
    ariaRole: 'alertdialog',
    confirmText: 'Refresh',
    ariaModal: true,
    canCancel: false,
    onConfirm: onConfirm || (() => {
      window.location.reload();
    }),
  });
}

export function confirmLeaveDialog(result: string, leaveText: string, stayText: string, onConfirm: BDialogConfig['onConfirm'], onCancel: BDialogConfig['onCancel']) {
  return DialogProgrammatic.confirm({
    title: 'Are you sure you want to leave?',
    message: `Navigating away from this page will ${result}. <br><br>Are you sure you want to leave?`,
    type: 'is-danger',
    hasIcon: false,
    ariaRole: 'alertdialog',
    ariaModal: true,
    confirmText: leaveText,
    cancelText: stayText,
    onConfirm,
    onCancel,
  });
}

export type OpenModalConfig = Omit<BModalConfig, 'component'> & {
  component?: Component
}

export function openModal(config: OpenModalConfig) {
  return ModalProgrammatic.open({
    parent: this,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'outside'],
    ...config,
  } as BModalConfig);
}

export function openLoadingIndicator(config: BLoadingConfig) {
    return LoadingProgrammatic.open(config);
}

export function customSortByString(strA: string | undefined, strB: string | undefined, asc: boolean) {
  if (!strA) {
    return asc ? 1 : -1;
  }
  if (!strB) {
    return asc ? -1 : 1;
  }
  return asc ? strA.localeCompare(strB) : strB.localeCompare(strA);
}

export function customSortTableByDate(dateA: Date | string, dateB: Date | string, isAsc: boolean) {
  return isAsc
    ? getDifferenceBetweenDateValues(dateA, dateB)
    : getDifferenceBetweenDateValues(dateB, dateA);
}

export function customSortByVehicleStatus(statusA: VehicleStatus, statusB: VehicleStatus, isAsc: boolean, statuses: Array<VehicleStatus>) {
  let statusAIdx = statuses.findIndex(status => status == statusA);
  let statusBIdx = statuses.findIndex(status => status == statusB);
  return isAsc
    ? statusAIdx - statusBIdx
    : statusBIdx - statusAIdx;
}

export function highlightTableRowsByClassName(className: string, color: string) {
  const rows = document.getElementsByClassName(className);
  Array.from(rows).forEach(row => {
    const tr = row.closest('tr');
    if (tr) {
      tr.style.backgroundColor = color;
    }
  });
}

export function highlightTableRowsById(id: string, color: string) {
  const anchorElement = document.getElementById(`background-color-${id}`);
  if (!anchorElement) {
    return;
  }
  const tr = anchorElement?.closest('tr');
  if (tr) {
    tr.style.backgroundColor = color;
  }
}

export function getInputMasks() {
  return {
    currency: {
      numeral: true,
      prefix: '$',
      numeralThousandsGroupStyle: 'none',
    },
    numeral: {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: '$',
    },
    numberThousands: {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    },
    numberThousandsPlus: {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: '+',
      tailPrefix: true,
    },
    number: {
      numeral: true,
      numeralThousandsGroupStyle: 'none',
    },
    numberPlus: {
      numeral: true,
      numeralThousandsGroupStyle: 'none',
      prefix: '+',
      tailPrefix: true,
    },
  };
}

// add className to b-dropdown element
export function fixDropdownPositionInModal(className: string='.modal-dropdown') {
  window.setTimeout(() => {
    const datepicker = document.querySelector(`${className}.is-active.is-mobile-modal`);
    const dropdownContent: Element & { style: any } | null | undefined = datepicker?.querySelector('.dropdown-content');
    if (dropdownContent) {
      dropdownContent.style.position = 'fixed';
      dropdownContent.style.width = 'inherit';
    }
  }, 100);
}

export function openErrorDialog(options: {
  title?: string,
  message?: string,
  error?: any, // server error
  displayErrorInDialog?: boolean,
  confirmText?: string, 
  onConfirm?: () => void,
} = {}) {
    const {
        title: customTitle,
        message: customMessage,
        error,
        displayErrorInDialog,
    } = options;

    const serverTitle = options?.error?.response?.data?.title;
    let serverMessage;

    if (error && displayErrorInDialog) {
        if (typeof error === 'string') {
            serverMessage = error;
        } else if (typeof error.response?.data?.error === 'string') {
            serverMessage = error.response.data.error;
        } else {
            serverMessage = error.data?.message;
        }

        // If failing on Celebrate validation, get the message
        if (serverMessage === 'Bad Request' && error?.response?.data?.validation?.body?.message) {
            serverMessage = error.response.data.validation.body.message;
        }
    }

    const dialogTitle = customTitle || (serverTitle || 'Something went wrong');

    const showOrHideCustomMessage = customMessage ? '' : 'display: none';
    const showOrHideServerMessage = serverMessage ? '' : 'display: none';

    openAlertDialog({
        title: dialogTitle,
        message: `<div>
          <div style="${showOrHideCustomMessage}">${customMessage}</div>
          <div style="${showOrHideServerMessage}" class="has-text-danger">
            Error: ${serverMessage}
          </div>
        </div>`,
        error,
        onConfirm: options.onConfirm,
        confirmText: options.confirmText,
    });
}

export function openDeveloperActionRequiredDialog(attemptedChanges: string) {
    openDialogAlert(
        'Developer action required',
        `<div>
          <p>To avoid potential disaster, please contact a developer to make these changes.<p>
          <p class="has-text-danger">
            Changes attempted: <br>
            ${attemptedChanges}
          </p>
        </div>
        `,
    );
}
