
import { isVehicleAuctioned, openModal } from '../../utils';
import { BidHistoryBid } from '../../types';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { BModalComponent } from 'buefy/types/components';

import AppButton from '../AppButton.vue';
import TheBidHistoryModal from '../TheBidHistoryModal.vue';

export type BidHistoryVehicle = {
    id: number,
    year: string,
    brand: string,
    model: string,
    heroPicUrl: string,
    vin: string,
    reservePrice: number,
    buyItNowPrice: number,
}

export default defineComponent({
    name: 'ViewBidHistoryButton',
    props: {
        vehicle: {
            type: Object as PropType<BidHistoryVehicle>,
            required: true,
        },
        highestBid: {
            type: Object,
            required: false,
        },
        highestProxyBid: {
            type: [Object, null],
            required: false,
        },
        bidHistory: {
            type: Array as PropType<BidHistoryBid[]>,
            required: false,
        }
    },
    components: {
        AppButton,
    },
    setup(props, context) {
        const openedModal: Ref<BModalComponent | null> = ref(null);

        const bidHistory = computed(() => props.bidHistory);
        const highestBid = computed(() => props.highestBid);
        
        function openBidHistoryModal() {
            if (openedModal.value) {
                return false;
            }
            openedModal.value = openModal({
                component: TheBidHistoryModal,
                props: {
                    bidHistory: bidHistory,
                    vehicle: props.vehicle,
                    highestBid: highestBid,
                    highestProxyBid: props.highestProxyBid,
                },
                events: {
                    close: () => {
                        openedModal.value = null;
                        context.emit('bidHistoryModalClose');
                    }
                }
            });
            context.emit('bidHistoryModalOpen', openedModal.value);
        }
        return {
            isVehicleAuctioned,
            openBidHistoryModal,
        }
    },
});
