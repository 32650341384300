import { APIConfig, PaginationDTO } from '@/types';
import { ModifierOptions } from '@/types/ModifierOptions';
import { applyAPIConfigOnSuccess } from '@/utils';
import { reactive, Ref, ref, watch } from 'vue';

type SearchBy = { field?: string, value?: string };
type FetchTableDataDTO = APIConfig & {
    modifiers?: ModifierOptions,
    updateTableData?: boolean,
    clearPagination?: boolean,
}

export function useTableModifiers({ initialModifiers }: {
    initialModifiers?: ModifierOptions,
}={}) {
    const modifiers: Ref<ModifierOptions | undefined> = ref(initialModifiers);

    function updateModifiers(updatedModifiers: Partial<ModifierOptions>, { replaceModifiers }: { replaceModifiers?: boolean }={}) {
        if (replaceModifiers) {
            modifiers.value = updatedModifiers;
        } else {
            modifiers.value = {
                ...modifiers.value,
                ...updatedModifiers,
            }
        }
    }

    return {
        modifiers,
        updateModifiers,
    }
}


export function useTable<T=any>({ getTableDataCallback, modifiers }: {
    getTableDataCallback: (modifiers?: ModifierOptions) => Promise<T[]>,
    modifiers: Ref<ModifierOptions | undefined>,
}) {
    const tableData: Ref<T[]> = ref([]);
    const loadingTableData: Ref<boolean> = ref(false);

    async function getTableData(config: FetchTableDataDTO={}): Promise<T[]> {
        if (config.clearPagination && modifiers.value?.pagination) {
            modifiers.value.pagination = undefined;
        }

        loadingTableData.value = true;
        const res = await getTableDataCallback(modifiers?.value);
        applyAPIConfigOnSuccess(res, config);
        loadingTableData.value = false;
        if (config.updateTableData) {
            tableData.value = res;
        }
        return res;
    }

    return {
        tableData,
        loadingTableData,
        getTableData,
    }
}

export function useTableSearch<T=any>({ tableData, modifiers, modifierSchemaCallback, getTableDataCallback, updatePagination }: {
    tableData: Ref<T[]>,
    getTableDataCallback: (config?: FetchTableDataDTO) => Promise<T[]>,
    modifierSchemaCallback?: (searchField?: string, searchValue?: string) => ModifierOptions | undefined,
    modifiers: Ref<ModifierOptions | undefined>,
    updatePagination?: (res: T[]) => void,
}) {
    const searchBy: SearchBy = reactive({
        field: undefined,
        value: undefined,
    });

    async function getTableDataFromSearch(searchByParams: { [ key: string ]: string }) {
        if (searchByParams && searchByParams?.value) {
            searchBy.field = searchByParams.field;
            searchBy.value = searchByParams.value;

            if (modifierSchemaCallback) {
                modifiers.value = modifierSchemaCallback(searchBy.field, searchBy.value);
            }
        } else {
            searchBy.field = undefined;
            searchBy.value = undefined;
            modifiers.value = undefined;
        }

        getTableDataCallback({ 
            clearPagination: true,
            onSuccess: (res) => {
                tableData.value = res;
                if (updatePagination) {
                    updatePagination(res);
                }
            } 
        });
    }

    return {
        searchBy,
        getTableDataFromSearch,
    }
}

export function useTablePagination<T=any>({ tableData, paginationLimit=25, initialPaginationOffset=0, getTableDataCallback, modifiers, updateModifiers }: {
    tableData: Ref<T[]>,
    getTableDataCallback: (config?: FetchTableDataDTO) => Promise<T[]>,
    modifiers: Ref<ModifierOptions | undefined>,
    updateModifiers: (updatedModifiers: Partial<ModifierOptions>, config?: { replaceModifiers: boolean }) => void,
    paginationLimit?: number,
    initialPaginationOffset?: number,
}) {
    const paginationObj: PaginationDTO = reactive({
        paginationLimit,
        paginationOffset: initialPaginationOffset,
    });
    const canLoadMore: Ref<boolean> = ref(true);

    async function getTableDataFromPagination() {
        if (!canLoadMore.value) {
            return;
        }

        updateModifiers({ pagination: paginationObj });

        getTableDataCallback({
            onSuccess: (res) => {
                tableData.value = tableData.value.concat(res);
                updatePagination(res);
            }
        });
    }

    function resetPaginationAndTableData() {
        tableData.value = [];
        canLoadMore.value = true;
        paginationObj.paginationLimit = paginationLimit;
        paginationObj.paginationOffset = initialPaginationOffset;
        updateModifiers({ pagination: paginationObj }, { replaceModifiers: true })
    }

    function updatePagination(res: T[]): void {
        canLoadMore.value = res?.length >= paginationLimit;
        if (res?.length) {
            paginationObj.paginationOffset += res.length;
        }
    }

    // if pagination is cleared on modifiers, update paginationObj to match 
    watch(() => modifiers.value, (oldValue, newValue) => {
        if (oldValue?.pagination !== newValue?.pagination) {
            paginationObj.paginationLimit = newValue?.pagination?.paginationLimit ?? paginationLimit;
            paginationObj.paginationOffset = newValue?.pagination?.paginationOffset ?? initialPaginationOffset;
        }
        if (!newValue) {
            canLoadMore.value = true;
        }
    }, { deep: true });

    return {
        paginationObj,
        canLoadMore,
        getTableDataFromPagination,
        resetPaginationAndTableData,
        updatePagination,
    }
}

export function useSearchableTable({ searchByFieldOptions, searchCallback, defaultSearchByField }: {
    searchByFieldOptions: { label: string, value: string }[],
    searchCallback: (searchField?: string, searchValue?: string) => any,
    defaultSearchByField?: string,
}): {
    getSearchResults: (searchByParams?: { [key: string]: string }) => void,
    loadingSearch: Ref<boolean>,
    isDisplayingSearchResults: Ref<boolean>,
    searchBy: { field?: string, value?: string }
} {
    const loadingSearch = ref(false);
    const isDisplayingSearchResults = ref(false);

    const searchBy: { field?: string, value?: string } = reactive({
        field: defaultSearchByField,
        value: undefined,
    });

    async function getSearchResults(searchByParams?: { [key: string]: string }) {
        loadingSearch.value = true;
        isDisplayingSearchResults.value = true;

        if (searchByParams && searchByParams?.value) {
            searchBy.field = searchByParams.field;
            searchBy.value = searchByParams.value;
        }

        await searchCallback(searchBy.field, searchBy.value);
        loadingSearch.value = false;
    }

    return {
        getSearchResults,
        loadingSearch,
        isDisplayingSearchResults,
        searchBy,
    }
}
