var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"width-100"},[_vm._t("button",function(){return [_c('AppButton',_vm._b({on:{"click":function($event){_vm.openCheckoutModal(); _vm.$emit('click');}}},'AppButton',{
                label: 'Checkout',
                type: 'is-danger',
                outlined: true,
                expanded: true,
                ..._vm.$attrs,
            },false))]},null,{ openCheckoutModal: _vm.openCheckoutModal })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }