
import { defineComponent, PropType } from 'vue';

import AuctionStatBids from './AuctionStatBids.vue';
import AuctionStatViews from './AuctionStatViews.vue';
import TheListingOffersCount from './TheListingOffersCount.vue';

export default defineComponent({
    name: 'TheListingDetailsViewAndCounts',
    props: {
        viewCount: {
            type: Number,
            default: 0,
        },
        bidCount: {
            type: Number,
            required: false,
        },
        offerCount: {
            type: Number,
            required: false,
        },
    },
    components: {
        AuctionStatBids,
        AuctionStatViews,
        TheListingOffersCount,
    },
    setup(props, { emit, attrs }) {
        return {
            
        }
    },
});
