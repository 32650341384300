var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bidding-buttons flex-column width-100"},[_c('div',{staticClass:"top-row",class:`${_vm.format} ${_vm.format == 'compact' ? 'flex-row' : 'flex-column'}`},[(_vm.highestBid.status === 'Auctioning')?_c('AppButton',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons == 'bid' || _vm.showButtons?.includes('bid')),expression:"showButtons == 'bid' || showButtons?.includes('bid')"}],on:{"click":function($event){$event.stopPropagation();return _vm.openBidModal('bid')}}},'AppButton',{
                label: 'Bid + $100',
                type: 'is-primary',
                disabled: _vm.disableAllBidButtons || _vm.isHighestBidder,
                loading: _vm.isLoading,
                outlined: true,
                expanded: true,
                ..._vm.$attrs
            },false)):_vm._e(),(_vm.highestBid.status === 'Auctioning')?_c('AppButton',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons == 'proxy' || _vm.showButtons?.includes('proxy')),expression:"showButtons == 'proxy' || showButtons?.includes('proxy')"}],on:{"click":function($event){$event.stopPropagation();return _vm.openBidModal('proxy')}}},'AppButton',{
                label: 'Proxy Bid',
                type: 'is-primary',
                disabled: _vm.disableAllBidButtons,
                loading: _vm.isLoading,
                outlined: true,
                expanded: true,
                ..._vm.$attrs,
            },false)):_vm._e()],1),(_vm.canBuyItNow && _vm.buyItNowPrice)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButtons == 'buyItNow' || _vm.showButtons?.includes('buyItNow')),expression:"showButtons == 'buyItNow' || showButtons?.includes('buyItNow')"}],staticClass:"bottom-row",class:`${_vm.format}`},[_c('AppButton',_vm._b({on:{"click":function($event){$event.stopPropagation();_vm.$emit('click'); _vm.openBidModal('buyItNow')}}},'AppButton',{
                type: 'is-primary',
                disabled: _vm.disableAllBidButtons || _vm.disableBuyItNowButton,
                loading: _vm.loadingStores,
                expanded: true,
                ..._vm.$attrs,
            },false),[_c('div',[_c('span',{staticClass:"buy-now"},[_vm._v("Buy It Now ")]),_c('span',{staticClass:"buy-now-price"},[_vm._v(_vm._s(_vm._f("currency")(_vm.buyItNowPrice ? _vm.buyItNowPrice : _vm.reservePrice)))])])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }