<template>
    <div>
        <div>
            <h3>Starting Price</h3>
            <p v-if="auctionHasBid" class="has-text-grey-light is-size-6">
                Cannot be edited once the auction has a bid
            </p>
            <InputField 
                v-model="updatedAuction.startingPrice"
                v-bind="{
                    type: 'currency',
                    useCustomStyles: false,
                    disabled: auctionHasBid,
                }"
            >
            </InputField>
        </div>

        <!-- Reserve Price -->
        <InputField 
            v-model="updatedAuction.reservePrice"
            v-bind="{
                label: 'Reserve Price',
                type: 'currency',
                useCustomStyles: false,
            }"
        />

        <!-- Buy-It-Now Price -->
        <div class="buy-it-now">
            <p class="bold">
                Buy-It-Now Price{{ updatedAuction.canBuyItNow ? '' : ' (disabled)' }}
            </p>
            <div class="flex-row justify-space-between align-center">
                <InputField 
                    v-model="updatedAuction.buyItNowPrice"
                    v-bind="{
                        type: 'currency',
                        useCustomStyles: false,
                        disabled: !updatedAuction.canBuyItNow,
                        customValidator: validateBuyItNowPrice,
                        customInvalidMessage: buyItNowPriceInvalidMessage,
                        shouldValidateCustom: true,
                    }"
                    class="width-80"
                    @invalidInput="$emit('validated', true)"
                />
                <AppSwitch 
                    class="ml-2"
                    v-model="updatedAuction.canBuyItNow"
                    @input="validateBuyItNowPrice(updatedAuction.buyItNowPrice)"
                />
            </div>
        </div>

        <div>
            <h3>Start Time</h3>
            <p v-if="auctionHasStarted" class="has-text-grey-light is-size-6">
                Cannot be edited once the auction has started
            </p>
            <ScheduleAuctionDateTimePickers 
                @startTime="updatedAuction.auctionStart = $event; toggleAutofillEndTime()"
                @endTime="updatedAuction.auctionEnd = $event"
                v-bind="{
                    initialStartTime: auctionStart,
                    initialEndTime: auctionEnd,
                    autofillEndTime,
                    datetimepickerAttrs: {
                        appendToBody: true,
                    },
                    startTimeAttrs: {
                        disabled: auctionHasStarted || isUserDsr,
                    },
                    endTimeAttrs: {
                        disabled: isUserDsr,
                    },
                    position: 'is-top-right',
                }"
            />
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { useSaveAuctionUpdates, useUserRole } from '../composables';
import { EditAuctionProps } from '../types';
import { dollarAmountToInt, dollarAmountToIntObj, getBuyItNowUnavailableReason, isBuyItNowAvailable } from '../utils';
import store from '@/vuex';

import AppSwitch from './AppSwitch.vue';
import InputField from './InputField.vue';
import ScheduleAuctionDateTimePickers from './ScheduleAuctionDateTimePickers.vue';

export default defineComponent({
    name: 'TheEditAuctionInput',
    props: {
        ...EditAuctionProps,
        numBids: {
            type: Number,
            default: 0,
        },
        triggerSubmit: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppSwitch,
        InputField,
        ScheduleAuctionDateTimePickers,
    },
    emits: ['toggleLoading', 'validated', 'savedUpdates'],
    setup(props, context) {
        const { isUserDsr } = useUserRole(store);

        // set up updatedAuction
        const updatedAuction = reactive({
            startingPrice: props.startingPrice,
            reservePrice: props.reservePrice,
            buyItNowPrice: props.buyItNowPrice,
            canBuyItNow: props.canBuyItNow,
            auctionStart: props.auctionStart,
            auctionEnd: props.auctionEnd,
        });

        const auctionHasBid = computed(() => props.numBids > 0);
        const auctionHasStarted = computed(() => props.auctionStart?.getTime() <= (new Date()).getTime());

        // only autofill end time if there is no initial start date, or if the start date has been edited
        // otherwise with an initial start/end time, the autofill will overwrite the initial end time
        const autofillEndTime = ref(!Boolean(props.auctionStart));
        function toggleAutofillEndTime() {
            autofillEndTime.value = true;
        }

        // validation
        watch(updatedAuction, () => {
            if (hasChanges.value) {
                let hasInvalidField = !isBuyItNowValid.value || Object.keys(updatedAuction).some(field => !isFieldValid(field));
                context.emit('validated', hasInvalidField);
            }
        });

        const hasChanges = computed(() => {
            return Object.keys(updatedAuction).some(field => {
                let updatedValue = updatedAuction[field];
                if (updatedValue?.length) {
                    updatedValue = dollarAmountToInt(updatedValue);
                }
                return updatedValue !== props[field];
            });
        });

        function isFieldValid(field) {
            const initialValue = props[field];
            let updatedValue = updatedAuction[field];

            let dollarAmountFields = ['startingPrice', 'reservePrice', 'buyItNowPrice'];
            if (dollarAmountFields.includes(field)) {
                updatedValue = dollarAmountToInt(updatedValue);
            }

            // can't clear a field that was previously filled
            if (initialValue !== undefined && updatedValue == undefined) {
                return false;
            }
            return true;
        }

        const buyItNowPriceInvalidMessage = ref('');
        const isBuyItNowValid = ref(false);
        function validateBuyItNowPrice(inputValue) {
            let inputValueInt = dollarAmountToInt(inputValue);

            buyItNowPriceInvalidMessage.value = getBuyItNowPriceInvalidMessage(inputValueInt);
            if (!inputValueInt && updatedAuction.canBuyItNow) {
                isBuyItNowValid.value = false;
            } else {
                isBuyItNowValid.value = isBuyItNowAvailable({
                    vehicleListingId: props.vehicleListingId,
                    buyItNowPrice: inputValueInt,
                    reservePrice: dollarAmountToInt(updatedAuction.reservePrice),
                    startingPrice: dollarAmountToInt(updatedAuction.startingPrice),
                });
            }
            return isBuyItNowValid.value;
        }

        function getBuyItNowPriceInvalidMessage(inputValue) {
            if (!inputValue && updatedAuction.canBuyItNow) {
                return 'Buy-It-Now price is required';
            }
            return getBuyItNowUnavailableReason({
                buyItNowPrice: inputValue,
                reservePrice: dollarAmountToInt(updatedAuction.reservePrice),
                startingPrice: dollarAmountToInt(updatedAuction.startingPrice),
            });
        }

        // save updates on triggerSubmit
        const { saveAuctionUpdates } = useSaveAuctionUpdates();
        watch(() => props.triggerSubmit, () => {
            let updatedAuctionWithIntPricing = dollarAmountToIntObj(updatedAuction, ['startingPrice', 'reservePrice', 'buyItNowPrice']);
            saveAuctionUpdates(updatedAuctionWithIntPricing, props, context);
        });

        return {
            isUserDsr,
            updatedAuction,
            auctionHasBid,
            auctionHasStarted,
            toggleAutofillEndTime,
            autofillEndTime,
            validateBuyItNowPrice,
            getBuyItNowPriceInvalidMessage,
            buyItNowPriceInvalidMessage,
        }
    }
});
</script>

<style scoped lang='postcss'>
h3 {
    font-family: 'Lato';
    font-weight: bold;
}

.width-80 {
    width: 80%;
}

:deep(.datepicker) {
    margin: 0 auto 6px;

    &:first-child {
        & label {
            display: none;
        }
    }
}

:deep(.input) {
    border-color: #CCCCCC !important;
}
</style>