import { didSellerAcceptTermsAndConditions, getAdminDashboardListingsByStatus, getAdminVehicleDetailsDTO, getAuctionDetails, getPlaceByVehicleListingId, getVehicleDetails, getVehicleSellerBasicDetails, getVehicleHighestBidderBasicDetails,  } from '@/api';
import { formatPhoneNumber, getInspectorPersonIdByVehicleListingId, isMarketplaceStatus, isUserDsr, openAssignInspectorModal, openEditListingLocationModal, openEditVehicleModal, openModal, openToast, toCurrency, toCurrencyShowDashesIfNull } from '@/utils';
import { getMarketplaceListingDetails } from '@/api/listings';
import { AdminDashboardDTO, HighestBidRefSnapshot, SortingDirection, VehicleStatus, VehicleStatusGroup } from '@/types';
import { reactive, Ref, ref } from 'vue';
import { pick } from 'lodash';

import ScheduleAuctionModal from '../components/ScheduleAuctionModal.vue';
import SelectSellFeeModal from '../components/Modals/SelectSellFeeModal.vue';

export function useEditListing({ vehicleListingId, emit }: {
    vehicleListingId: number,
    emit: (event: 'assignInspector', ...args: any[]) => void,
}) {
    const loadingVehicleDetails = ref(false);

    async function getVehicleDetailsAndOpenEditVehicleModal() {
        loadingVehicleDetails.value = true;
        let vehicleDetails = await getAdminVehicleDetailsDTO(vehicleListingId, {
            onSuccess: () => loadingVehicleDetails.value = false,
            onError: () => loadingVehicleDetails.value = false,
        });
        openEditVehicleModal(vehicleListingId, {
            ...vehicleDetails,
            year: vehicleDetails.year.toString(),
            condition: vehicleDetails.vehicleCondition,
        });
    }

    const loadingVehicleLocation = ref(false);
    async function getVehicleLocationAndOpenEditLocationModal() {
        loadingVehicleLocation.value = true;
        let { postalCode, city, state } = await getPlaceByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingVehicleLocation.value = false,
            onError: () => loadingVehicleLocation.value = false,
        });
        openEditListingLocationModal(vehicleListingId, {
            zipCode: postalCode,
            city,
            state
        });
    }

    function openAssignInspectorModalWithEmit(inspectorPersonId?: number | null) {
        openAssignInspectorModal(vehicleListingId, inspectorPersonId, { emit });
    }

    const loadingInspectorPersonId = ref(false);
    async function getAssignedInspectorAndOpenAssignInspectorModal() {
        loadingInspectorPersonId.value = true;
        let inspectorPersonId = await getInspectorPersonIdByVehicleListingId(vehicleListingId, {
            onSuccess: () => loadingInspectorPersonId.value = false,
            onError: () => loadingInspectorPersonId.value = false,
        });
        openAssignInspectorModalWithEmit(inspectorPersonId);
    }

    return {
        loadingInspectorPersonId,
        getAssignedInspectorAndOpenAssignInspectorModal,
        openAssignInspectorModalWithEmit,
        getVehicleLocationAndOpenEditLocationModal,
        loadingVehicleLocation,
        getVehicleDetailsAndOpenEditVehicleModal,
        loadingVehicleDetails,
    }
}

export function usePreAuctionFunctions({ vehicleListingId }: {
    vehicleListingId: number,
}) {
    const loadingCheckTermsAndConditions = ref(false);
    const sellerAcceptedTermsAndConditions: Ref<boolean | undefined> = ref(undefined);
    async function checkTermsAndConditionsAccepted() {
        loadingCheckTermsAndConditions.value = true;
        sellerAcceptedTermsAndConditions.value = await didSellerAcceptTermsAndConditions(vehicleListingId, {
            onSuccess: () => loadingCheckTermsAndConditions.value = false,
            onError: () => loadingCheckTermsAndConditions.value = false,
        });
    }

    function openSelectSellFeeModal({ sellerStoreId, onSuccess=() => {}, onClose=() => {}}: {
        sellerStoreId?: number,
        onSuccess?: () => void,
        onClose?: (event: { isCancelled?: boolean }) => void,
    }) {
        openModal({
            component: SelectSellFeeModal,
            props: {
                vehicleListingId,
                sellerStoreId,
            },
            events: {
                success: onSuccess,
                close: onClose,
            }
        });
    }


    function openScheduleAuctionModal({ onSuccess=() => {} }: {
        onSuccess: () => void,
    }) {
        openModal({
            component: ScheduleAuctionModal,
            props: {
                vehicleListingId,
                hasMobileView: true,
                autofillEndTime: true,
            },
            events: {
                success: onSuccess,
                close: onSuccess,
            }
        });
    }

    async function selectSellFeeAndScheduleAuction({
        sellerStoreId, vehicleStatus, onError=() => {}, onSellFeeSelected=() => {}, onSellFeeClose=() => {}, onAuctionScheduled=() => {},
    }: {
        sellerStoreId?: number,
        vehicleStatus: VehicleStatus,
        onError: (error: string) => void,
        onSellFeeClose: (event?: { isCancelled?: boolean }) => void,
        onSellFeeSelected: () => void,
        onAuctionScheduled: () => void,
    }) {
        // if status can't schedule auctions, return early
        let canScheduleAuctionStatuses = ['InspectionScheduled', 'Inspected', 'MarketplacePending'];
        if (!canScheduleAuctionStatuses.includes(vehicleStatus)) {
            onError('status');
            openToast('is-danger', `Status ${vehicleStatus} cannot schedule auctions`);
            return;
        }
        const isMarketplaceListing: boolean = isMarketplaceStatus(vehicleStatus);

        // if not marketplace, check T&C
        if (!isMarketplaceListing) {
            await checkTermsAndConditionsAccepted();

            // if T&C not accepted, return early
            if (!sellerAcceptedTermsAndConditions.value) {
                onError('termsAndConditionsNotAccepted');
                openToast('is-danger', 'Seller has not accepted Terms & Conditions');
                return;
            }
        }

        // select sell fee
        openSelectSellFeeModal({
            sellerStoreId,
            onSuccess: () => {
                // if marketplace, return early
                if (isMarketplaceListing || isUserDsr()) {
                    onSellFeeSelected();
                    return;
                }

                // schedule auction
                openScheduleAuctionModal({
                    onSuccess: onAuctionScheduled, // do something after scheduling
                });
            },
            onClose: onSellFeeClose,
        });
    }
    return {
        selectSellFeeAndScheduleAuction,
    }
}

export function useVehicleDetailsCards({ vehicleListingId }: { vehicleListingId: number }) {
    const loadingVehicleBasicDetails = ref(false);
    const vehicleBasicDetails = ref({
        id: '--',
        year: '--',
        make: '--',
        model: '--',
        trim: '--',
        vin: '--',
        mileage: '--',
    });

    async function getVehicleBasicDetails() {
        loadingVehicleBasicDetails.value = true;
        await getVehicleDetails(vehicleListingId, {
            onSuccess: (res) => {
                vehicleBasicDetails.value = pick(res, ['id', 'year', 'make', 'model', 'trim', 'vin', 'mileage']);
                vehicleBasicDetails.value.mileage = vehicleBasicDetails.value.mileage ? vehicleBasicDetails.value.mileage.toLocaleString() : '--';
                if (!vehicleBasicDetails.value.trim) {
                    vehicleBasicDetails.value.trim = '--';
                }
                loadingVehicleBasicDetails.value = false;
            }, 
            onError: () => loadingVehicleBasicDetails.value = false,
        });
    }

    const loadingAuctionDetails = ref(false);
    const auctionDetails = ref({
        highestBidderStore: '--',
        highestBidder: '--',
        reserve: '--',
        highestBid: '--',
        secondHighestBidderStore: '--',
        secondHighestBidder: '--',
        outTheDoorPrice: '--',
    });
    async function getVehicleAuctionDetails() {
        await getAuctionDetails(vehicleListingId, {
            onSuccess: (res) => {
                auctionDetails.value = {
                    highestBidderStore: res.highestBidder.storeName,
                    highestBidder: `${res.highestBidder.firstName} ${res.highestBidder.lastName}`, 
                    reserve: toCurrencyShowDashesIfNull(res.reserve),
                    highestBid: toCurrencyShowDashesIfNull(res.highestBid),
                    secondHighestBidderStore: res.secondHighestBidder?.storeName ?? '--', 
                    secondHighestBidder: res.secondHighestBidder ? `${res.secondHighestBidder.firstName} ${res.secondHighestBidder?.lastName}` : '--',
                    outTheDoorPrice: toCurrencyShowDashesIfNull(res.highestBidderOutTheDoorPrice),
                }
                loadingAuctionDetails.value = false;
            },
            onError: () => loadingAuctionDetails.value = false,
        });
    }
    

    const loadingSellerDetails = ref(false);
    const sellerDetails = ref({
        sellerType: '--',
        name: '--',
        phone: '--',
        email: '--', 
        location: '--',
        sellFee: '--',
    });
    async function getSellerDetails() {
        loadingSellerDetails.value = true;
        await getVehicleSellerBasicDetails(vehicleListingId, {
            onSuccess: (res) => {
                sellerDetails.value = {
                    sellerType: res.sellerType,
                    name: `${res.firstName} ${res.lastName}`, 
                    phone: formatPhoneNumber(res.phoneNumber) ?? '--',
                    email: res.email ?? '--',
                    location: res.address ? `${res.address.city}, ${res.address.state} ${res.address.zip}` : '--', 
                    sellFee: toCurrencyShowDashesIfNull(res.sellFee),
                }
                loadingSellerDetails.value = false;
            },
            onError: () => loadingSellerDetails.value = false,
        });
    }

    const loadingBuyerDetails = ref(false);
    const buyerDetails = ref({
        name: '--',
        phone: '--',
        email: '--',
        location: '--',
        buyFee: '--',
        transportFee: '--',
    });
    async function getBuyerDetails() {
        loadingBuyerDetails.value = true;
        await getVehicleHighestBidderBasicDetails(vehicleListingId, {
            onSuccess: (res) => {
                buyerDetails.value = {
                    name: `${res.firstName} ${res.lastName}`,
                    phone: formatPhoneNumber(res.phoneNumber) ?? '--',
                    email: res.email ?? '--',
                    location: res.location ? `${res.location.city}, ${res.location.state} ${res.location.zip}` : '--',
                    buyFee: toCurrencyShowDashesIfNull(res.buyFee),
                    transportFee: toCurrencyShowDashesIfNull(res.transportCost),
                }
                loadingBuyerDetails.value = false;
            },
            onError: () => loadingBuyerDetails.value = false,
        });
    }

    const loadingMarketplaceDetails = ref(false);
    const marketplaceDetails = reactive({
        'Marketplace Listing ID': '--',
        'Floor Price': '--',
        'Buy-It-Now Price': '--',
        'Highest Offer': '--',
        'Highest Offerer': '--',
        'View count': '--',
        'Offer count': '--',
    });
    const marketplaceDetailsRaw = ref(undefined);
    async function getMarketplaceDetails(marketplaceListingId: number) {
        loadingMarketplaceDetails.value = true;
        await getMarketplaceListingDetails(marketplaceListingId, {
            onSuccess: (listing) => {
                marketplaceDetailsRaw.value = listing;
                console.log('marketplaceDetailsRaw', marketplaceDetailsRaw.value);
                marketplaceDetails['Marketplace Listing ID'] = listing.marketplaceListingId;
                marketplaceDetails['Floor Price'] = listing.reservePrice ? toCurrency(listing.reservePrice) : '--';
                marketplaceDetails['Buy-It-Now Price'] = listing.buyItNowPrice ? toCurrency(listing.buyItNowPrice) : '--';
                marketplaceDetails['Highest Offer'] = listing.highestOfferAmount ? toCurrency(listing.highestOfferAmount) : '--';
                marketplaceDetails['Highest Offerer'] = listing.highestOfferStoreName ?? '--';
                marketplaceDetails['View count'] = listing.numViews;
                marketplaceDetails['Offer count'] = listing.numOffers;
                loadingMarketplaceDetails.value = false;
            },
            onError: () => loadingMarketplaceDetails.value = false,
        });
    }

    const loadingSecondChanceDetails = ref(false);
    const secondChanceDetails = reactive({
        'Marketplace Listing ID': '--',
        'Reserve Price': '--',
        'Highest Offer': '--',
        'Highest Offerer': '--',
        'View count': '--',
        'Offer count': '--',
    });
    const secondChanceDetailsRaw = ref(undefined);
    async function getSecondChanceDetails(marketplaceListingId: number) {
        loadingSecondChanceDetails.value = true;
        await getMarketplaceListingDetails(marketplaceListingId, {
            onSuccess: (listing) => {
                secondChanceDetailsRaw.value = listing;
                secondChanceDetails['Marketplace Listing ID'] = listing.marketplaceListingId;
                secondChanceDetails['Reserve Price'] = listing.reservePrice ? toCurrency(listing.reservePrice) : '--';
                secondChanceDetails['Highest Offer'] = listing.highestOfferAmount ? toCurrency(listing.highestOfferAmount) : '--';
                secondChanceDetails['Highest Offerer'] = listing.highestOfferStoreName ?? '--';
                secondChanceDetails['View count'] = listing.numViews;
                secondChanceDetails['Offer count'] = listing.numOffers;
                loadingSecondChanceDetails.value = false;
            },
            onError: () => loadingSecondChanceDetails.value = false,
        });
    }

    return {
        getVehicleBasicDetails,
        vehicleBasicDetails,
        loadingVehicleBasicDetails,

        getVehicleAuctionDetails,
        auctionDetails,
        loadingAuctionDetails,

        getSellerDetails,
        sellerDetails,
        loadingSellerDetails,

        getBuyerDetails,
        buyerDetails,
        loadingBuyerDetails,

        getMarketplaceDetails,
        marketplaceDetails,
        marketplaceDetailsRaw,
        loadingMarketplaceDetails,

        getSecondChanceDetails,
        secondChanceDetails,
        secondChanceDetailsRaw,
        loadingSecondChanceDetails,
    }
}

export function useAdminDashboardTable({ status, sortBy='vehicleListing.id', sortByDirection='desc', paginationLimit=25, paginationOffset=0 }: {
    status: VehicleStatusGroup,
    sortBy?: string,
    sortByDirection?: SortingDirection,
    paginationLimit?: number,
    paginationOffset?: number,
}) {
    const loadingTableData = ref(false);
    const tableData: Ref<AdminDashboardDTO[]> = ref([]);
    const checkedRows: Ref<AdminDashboardDTO[]> = ref([]);
    const paginationAndSorting = reactive({
        paginationLimit,
        paginationOffset,
        orderBy: sortBy,
        orderByDirection: sortByDirection,
    });
    const canLoadMore: Ref<boolean> = ref(true);

    async function getTableData({ fromPagination }: { fromPagination?: boolean }={}) {
      loadingTableData.value = true;
      await getAdminDashboardListingsByStatus(status, paginationAndSorting, {
        onSuccess: (newListings) => {
            loadingTableData.value = false;

            tableData.value = fromPagination 
                ? tableData.value.concat(newListings)
                : newListings;

            paginationAndSorting.paginationOffset += newListings.length;
            canLoadMore.value = newListings.length >= paginationAndSorting.paginationLimit;
        },
        onError: () => loadingTableData.value = false,
      });
    }

    function getTableDataWithSorting(newSortBy: string, newSortByDirection: SortingDirection) {
        if (newSortBy !== paginationAndSorting.orderBy || newSortByDirection !== paginationAndSorting.orderByDirection) {
            canLoadMore.value = true;
            paginationAndSorting.paginationOffset = 0;
            paginationAndSorting.orderBy = newSortBy;
            paginationAndSorting.orderByDirection = newSortByDirection;
            getTableData();
        }
    }

    function updateAssignedInspector(vehicleListingId: number, inspectorPersonId: number) {
        let rowIdx = tableData.value.findIndex(row => parseInt(row.id) == vehicleListingId);
        if (rowIdx >= 0) {
            tableData.value[rowIdx].inspectorPersonId = inspectorPersonId;
        }
    }

    function removeListingsFromTable(vehicleListingIds: number[]) {
        vehicleListingIds.forEach(vehicleListingId => {
            let rowIdx = tableData.value.findIndex(row => parseInt(row.id) == vehicleListingId);
            if (rowIdx >= 0) {
                tableData.value.splice(rowIdx, 1);
            }
        });
    }

    function updateTableRowOnRealtimeUpdate(vehicleListingId: number, { newSnapshotValue }: HighestBidRefSnapshot) {
        let listing = tableData.value.find(row => row.id == vehicleListingId.toString());
        if (!listing) {
            return;
        }

        // Update auction dates if changed
        if (listing.auctionStartDate !== newSnapshotValue.auctionStart) {
            listing.auctionStartDate = newSnapshotValue.auctionStart;
        }
        if (listing.auctionEndDate !== newSnapshotValue.auctionEnd) {
            listing.auctionEndDate = newSnapshotValue.auctionEnd;
        }

        // Status change
        if (listing.status !== newSnapshotValue.status) {
            removeListingsFromTable([vehicleListingId]);
            openToast('is-success', `A ${listing.year} ${listing.make} ${listing.model} (ID: ${listing.id}) moved to ${newSnapshotValue.status} status`);
            return;
        }
    }

    return {
        checkedRows,
        getTableData,
        getTableDataWithSorting,
        tableData,
        loadingTableData,
        updateAssignedInspector,
        paginationAndSorting,
        canLoadMore,
        removeListingsFromTable,
        updateTableRowOnRealtimeUpdate,
    }
}
