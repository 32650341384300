
import moment from 'moment-timezone';
import { computed, defineComponent, PropType } from 'vue';
import { useUser } from '../composables';
import { BidHistoryBid } from '../types';
import { formatPhoneNumber, toCurrency } from '../utils';

import ThePowerUserIcon from './ThePowerUserIcon.vue';

export default defineComponent({
    name: 'TheBidInfoCard',
    props: {
        bid: {
            type: Object as PropType<BidHistoryBid>,
            required: true,
        },
        flag: {
            type: String,
            required: false,
        },
        isHighlighted: {
            type: Boolean,
            default: false,
        },
        isFocused: {
            type: Boolean,
            default: false,
        },
        highestBidAmount: {
            type: Number,
            required: false,
        },
        reservePrice: {
            type: Number,
            required: false,
        },
        focusedClassName: {
            type: String,
            required: false,
        }
    },
    components: {
        ThePowerUserIcon,
    },
    setup(props, { emit, attrs }) {
        const createdDate = moment(props.bid.created);
        const time = createdDate.format('h:mm:ss A');
        const date = createdDate.format('MM.DD.YYYY');

        const user = useUser();
        const isMyBid = computed(() => {
            return props.bid.personId == parseInt(user.value.profile.id);
        });
        
        const isReserveMet = computed(() => props.reservePrice == props.bid.amount);
        const isHighestBid = computed(() => props.highestBidAmount == props.bid.amount);
        const isFocused = computed(() => {
            if (props.focusedClassName == `buyer-${props.bid.personId}`) {
                return true;
            }
            if (isReserveMet.value && props.focusedClassName == 'reservePrice') {
                return true;
            }
            if (isHighestBid.value && props.focusedClassName == 'highestBid') {
                return true;
            }
            return false;
        });

        return {
            formatPhoneNumber,
            toCurrency,
            time,
            date,
            isMyBid,
            isFocused,
            isReserveMet,
            isHighestBid,
        }
    },
});
