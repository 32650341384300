<template>
    <!-- TODO: replace gavel icon with our custom gavel img in white -->
    <AuctionStat 
        :name="text"
        :value="bidCount"
        icon="gavel"
        useIcon
    />
</template>

<script>
import AuctionStat from "./AuctionStat.vue"
export default {
    name: 'AuctionStatBids',
    props: {
        bidCount: Number,
        text: {
            type: String,
            required: false,
            default: 'Bids',
        }
    },
    components: {
        AuctionStat
    },
}
</script>
