<template>
<!-- View, bid, photos count -->
    <h2 class="auction-stats to-uppercase align-center flex-row">
        <div v-if="$attrs.loading" class="loading-skeleton">
            <AppLoadingSkeleton height="20px" width="25px" />
            <AppLoadingSkeleton height="15px" width="50px" />
        </div>
        <div v-else>
            <b-icon v-if="useIcon" :icon="icon" custom-size="mdi-18px" class="mb-0" />
            <img 
                :src="getImgUrl()"
                :alt="name"
                v-else
            />
        </div>
        <div v-if="!$attrs.loading" class="flex-row align-end">
            <span>{{ value }}</span>
            <span v-if="showLabel" class="stat-label pl-1 is-poppins">{{ name }}</span>
        </div>
    </h2>
</template>

<script>
import AppLoadingSkeleton from './AppLoadingSkeleton.vue';

export default {
    name: 'AuctionStat',
    props: {
        name: String,
        value: {
            type: String | Number,
            default: 0,
        },
        icon: String,
        showLabel: {
            type: Boolean,
            default: true,
        },
        useIcon: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AppLoadingSkeleton,
    },
    methods: {
        getImgUrl() {
            return require(`../assets/svg/${this.icon}`)
        }
    },
}
</script>

<style scoped lang='postcss'>

.auction-stats {
    margin-bottom: 0 !important;
    
    & h2 {
        font-weight: 400;
    }
  & img, & .icon {
    margin-right: 5px;
  }

  color: #b9b9b9;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;

  @media screen and (max-width: 380px) {
      font-size: 12px;
  }
}

</style>