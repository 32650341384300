<template>
  <div id="app" :class="{ 'booking-panel-open': $store.state.fastBookBookingPanel.isOpen }">
    <div v-if="isLoadingDomain" class="margin-auto height-100">Loading...</div>
    <NavTop 
      v-if="!hideNavBar && !isLoadingDomain" 
      :key="`nav-${isLoading}-${navtopKey}`" 
      :navtopLogo="navtopLogo"
      :displayNotificationFeed.sync="displayNotificationFeed"
    />
    <transition v-if="!isLoadingDomain" :name="transitionName">
      <router-view class="view-frame"></router-view>
    </transition>
    <TheFastBookBookingPanel v-if="$store.state.isFastBookExtensionInstalled" />
    <TheNotificationFeedPanel v-if="$store.state?.user?.profile?.id" :open.sync="displayNotificationFeed" />
  </div>
</template>

<script>
import bidderGiveawayMixin from '@/mixins/bidderGiveaway';
import { useRoute } from 'vue-router/composables';
import { computed } from 'vue';
import NavTop from './components/NavTop/NavTop';
import TheFastBookBookingPanel from './components/TheFastBookBookingPanel.vue';
import TheNotificationFeedPanel from './components/TheNotificationFeedPanel.vue';
import { APP_TARGET, GET, getCheckoutCount, getNegotiatingCount } from './api';
import { 
  applyBrandingForOrigin,
  getAndDisplayUserNotifications,
  getUnreadNotificationIdsByUserIdFromNotificationRef,
  isCarmigoDomain,
  isFastBookExtensionInstalled,
  openErrorRefreshAppDialog,
  requireStylesheetForOrigin,
} from './utils';
import { useBreakpoint, useHubSpot, useNotificationListener } from './composables';
import store from '@/vuex';

export default {
  name: 'App',
  components: {
    NavTop,
    TheFastBookBookingPanel,
    TheNotificationFeedPanel,
  },
  mixins: [bidderGiveawayMixin],
  data() {
    return {
      hideNavBar: true,
      segmentKey: '',
      isLoading: false,
      navtopLogo: '',
      navtopKey: 0,
      isLoadingDomain: false,
      domainOriginUpdated: false,
      displayNotificationFeed: false,
    };
  },
  async beforeUpdate() {
    this.hideNavBar = Boolean(this.$route.meta.hideNavbar);

    await this.checkAppBrandingForDomain();
  },
  async mounted() {
    this.$root.$on('brandingApplied', () => {
      this.navtopKey++;
    });

    this.checkFastbookExtension();
  },
  methods: {
    applyBrandingForOrigin,
    openErrorRefreshAppDialog,
    requireStylesheetForOrigin,

    async checkAppBrandingForDomain() {
      if (this.domainOriginUpdated) {
        return;
      }

      // this method sends them to Set Origin, so if already going there then return.
      const settingOriginRoute = this.$route.name === 'Set Origin' && Boolean(this.$route.params.publicId);
      if (settingOriginRoute) {
        this.domainOriginUpdated = true;
        return;
      }

      this.isLoadingDomain = true;

      // check whether domain is Carmigo
      const domain = window.location.host;

      const carmigoDomainCheck = isCarmigoDomain();

      // make sure Carmigo domain has Carmigo styles
      if (carmigoDomainCheck && !this.domainOriginUpdated) {
        this.domainOriginUpdated = true; // prevent doing this more than once
        return this.applyCarmigoBranding();
      }

      // make sure non-Carmigo domains have non-Carmigo branding
      if (!carmigoDomainCheck && !this.domainOriginUpdated) {
        this.domainOriginUpdated = true; // prevent doing this more than once
        return this.applyCarmigoDirectBranding(domain);
      }

      this.isLoadingDomain = false;
    },

    async applyCarmigoBranding() {
      await import('@/assets/branding/carmigo.css');
      const carmigoOriginPublicId = 'af502936-c509-4d8d-89fb-7c78711c9412';

      // if branding originPublicId isn't carmigo, apply carmigo branding
      const hasBranding = this.$store.state.branding;
      const wrongBranding = hasBranding && this.$store.state.branding.originPublicId !== carmigoOriginPublicId;
      if (wrongBranding) {
        await this.applyBrandingForOrigin(carmigoOriginPublicId);
      }

      // check if origin is CarmigoDirect before setting Carmigo origin
      // this is to prevent overwriting non-CarmigoDirect partnership origins
      const hasOrigin = this.$store.state.origin && this.$store.state.origin.publicId;
      if (hasOrigin) {
        const originPublicId = this.$store.state.origin.publicId;
        if (originPublicId == carmigoOriginPublicId) {
          this.isLoadingDomain = false;
          return;
        }
        const isCarmigoDirectOrigin = await GET(`/carmigoDirect/checkOrigin/${originPublicId}`)
          .then((res) => res.data)
          .catch((error) => {
            console.log('Error checking originPublicId for Carmigo Direct status', error);
            this.openErrorRefreshAppDialog();
          });
        if (isCarmigoDirectOrigin) {
          this.$router.push(`/origin/${carmigoOriginPublicId}?main=true`);
          this.isLoadingDomain = false;
          return;
        }
      }
      this.isLoadingDomain = false;
    },

    async applyCarmigoDirectBranding(domain) {
      await this.requireStylesheetForOrigin();

      // get originPublicId for this domain
      const domainOriginPublicId = await GET(`/carmigoDirect/originPublicId/${domain}`).then((res) => res.data);
      const missingOrigin = !this.$store.state.origin || !this.$store.state.origin.publicId;
      const wrongOrigin = this.$store.state.origin && this.$store.state.origin.publicId !== domainOriginPublicId;
      const missingBranding = !this.$store.state.branding;
      const wrongBranding = this.$store.state.branding && this.$store.state.branding.originPublicId !== domainOriginPublicId;

      if (missingOrigin || wrongOrigin || missingBranding || wrongBranding) {
        if (this.$route.name === 'Carmigo Direct Seller Car Profile' && this.$route.params.vehicleListingId) {
          const { vehicleListingId } = this.$route.params;
          this.$store.commit('updateMyNewCar', { id: vehicleListingId });
        }
        this.$router.push(`/origin/${domainOriginPublicId}?cd=true`);
      }
      this.isLoadingDomain = false;
    },

    getSegmentKeyForEnvironment(target) {
      if (target === 'prod') {
        this.segmentKey = 'QeFrPmEYgYWPiLSoxIbpHnQOv3zJ5oCZ';
      } else if (target === 'dev') {
        this.segmentKey = 'aLlkPx1QDjJXa3vtFmYN4in4HmHV5M6d';
      } else if (target === 'staging') {
        this.segmentKey = 'JX9Z7eB8gkJxepHkcBMiWZS24Yof7AfV';
      }
    },

    embedGoogleTagManager() {

      const body = document.querySelector('body');
      const header = document.querySelector('head');

      const GAScript = document.createElement('script');
      const GANoScriptIFrame = document.createElement('iframe');

      if (!isCarmigoDomain && APP_TARGET === 'prod') {
        GAScript.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PM9FWZS');";
        GANoScriptIFrame.setAttribute(
          'src',
          'https://www.googletagmanager.com/ns.html?id=GTM-PM9FWZS',
        )
      } else if (APP_TARGET === 'prod' && window.location.hostname === 'app.carmigo.io') {
        GAScript.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7QDJN4');";
        GANoScriptIFrame.setAttribute(
          'src',
          'https://www.googletagmanager.com/ns.html?id=GTM-W7QDJN4',
        );
      } else if (APP_TARGET === 'staging' && window.location.hostname === 'staging.app.carmigo.io') {
        GAScript.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=fYr8gFJkmiI1u74UOl-KMQ&gtm_preview=env-338&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7QDJN4');";
        GANoScriptIFrame.setAttribute(
          'src',
          'https://www.googletagmanager.com/ns.html?id=GTM-W7QDJN4&gtm_auth=fYr8gFJkmiI1u74UOl-KMQ&gtm_preview=env-338&gtm_cookies_win=x',
        );
      } else {
        GAScript.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=OjieJto88jAPp1KCaGaevw&gtm_preview=env-337&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7QDJN4');";
        GANoScriptIFrame.setAttribute(
          'src',
          'https://www.googletagmanager.com/ns.html?id=GTM-W7QDJN4&gtm_auth=OjieJto88jAPp1KCaGaevw&gtm_preview=env-337&gtm_cookies_win=x',
        );
      }

      header.appendChild(GAScript);

      // Google Tag Manager (noscript)
      const GANoScript = document.createElement('noscript');

      GANoScriptIFrame.setAttribute('height', '0');
      GANoScriptIFrame.setAttribute('width', '0');
      GANoScriptIFrame.setAttribute('style', 'display:none;visibility:hidden');

      GANoScript.appendChild(GANoScriptIFrame);
      body.prepend(GANoScript);
    },

    embedSegment() {
      const body = document.querySelector('body');
      const segmentScript = document.createElement('script');
      segmentScript.setAttribute('type', 'text/javascript');
      segmentScript.innerText = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${this.segmentKey}";;analytics.SNIPPET_VERSION="4.15.3";analytics.load("${this.segmentKey}");analytics.page();}}();`; // DEV
      body.appendChild(segmentScript);
    },

    async checkFastbookExtension() {
      const isInstalled = await isFastBookExtensionInstalled();
      this.$store.commit('setIsFastBookExtensionInstalled', isInstalled);
    }
  },
  created() {
    this.getSegmentKeyForEnvironment(APP_TARGET);

    this.embedGoogleTagManager();
    this.embedSegment();
  },
  setup() {
        if (APP_TARGET === 'prod') {
            useHubSpot();
        }

        const route = useRoute();
        const { bulmaBreakpoint } = useBreakpoint();

        const transitionName = computed(() => {
            if (!bulmaBreakpoint.value.mobile) {
                return '';
            }

            return route.meta?.transitionName ?? '';
        });

        const userId = computed(() => store?.state?.user?.profile?.userId);

        useNotificationListener(userId, {
          onSnapshot: ({ newSnapshotValue, oldSnapshotValue }) => {
            displayInAppNotificationsOnRdbUpdate(newSnapshotValue);
            updateActionRequiredCounts(newSnapshotValue, oldSnapshotValue);
          }, 
          onBeforeConnectListener: async () => {
            // prevents notifications from while app was closed (logged-out or not) from being spammed all at once on re-open
            let unreadNotifIds = await getUnreadNotificationIdsByUserIdFromNotificationRef(userId.value);
            store.commit('setNotificationIdsUnreadOnLogin', unreadNotifIds);
          }
        });

        function displayInAppNotificationsOnRdbUpdate(newSnapshotValue) {
          if (!newSnapshotValue?.inAppNotifications) {
            return;
          }
          const { inAppNotifications } = newSnapshotValue;
          let notificationIds = Object.keys(inAppNotifications).map(value => Number(value));
          let notificationIdsToDisplay = notificationIds;
          // filter out notifications that were queued from before login to avoid bombarding the newly logged-in user
          // these notifications can be viewed from the Notification Feed page
          const unreadNotificationIdsOnLogin = store?.state?.notificationIdsUnreadOnLogin;
          if (unreadNotificationIdsOnLogin && notificationIds?.length) {
            notificationIdsToDisplay = notificationIds.filter(id => !unreadNotificationIdsOnLogin.includes(id));
          }
          if (notificationIdsToDisplay) {
            getAndDisplayUserNotifications(notificationIdsToDisplay);
          }
        }

        function updateActionRequiredCounts(newSnapshotValue, oldSnapshotValue) {
          if (!newSnapshotValue) {
            return;
          }
          if (!oldSnapshotValue) {
            getCheckoutCount();
            getNegotiatingCount();
            return;
          }
          const { actionRequired: actionRequiredNew } = newSnapshotValue;
          const { actionRequired: actionRequiredOld } = oldSnapshotValue;

          if (actionRequiredNew?.checkout?.updatedDate !== actionRequiredOld?.checkout?.updatedDate) {
            getCheckoutCount();
          }
          if (actionRequiredNew?.negotiation?.updatedDate !== actionRequiredOld?.negotiation?.updatedDate) {
            getNegotiatingCount();
          }
        }

        return {
            transitionName,
        };
  },
};
</script>

<style lang="postcss">
:root {
  --border-radius: 0.2rem;
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}

#app {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html,
body {
  background: var(--grey-lighter);
  -webkit-font-smoothing: antialiased;
}
body,
input,
button {
  font-family: sans-serif;
  font-size: 14px;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
}

.view-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto 0;
}

/* TAB STYLING */
.tabs.is-boxed ul {
  & li{
    margin: 0.075rem 0.1rem;

    &.is-active a {
      border: none;
      border-bottom: var(--primary) .124rem solid !important;
      background-color: var(--white);
      font-weight: 600;
    }
  }
}

.b-tabs .tab-content {
  & .no-auctions {
    background-color: var(--white);
  }
}

/* tab content white background (desktop only) */
@media screen and (min-width: 601px) {

  .b-tabs .tab-content {
    background-color: var(--white) !important;
    min-height: 81vh;
    overflow: scroll;
  }

  .auctions .b-tabs .tab-content {
    min-height: fit-content;
  }
}

@media screen and (max-width: 600px) {
  /* MOBILE TAB STYLING */
  .tabs.is-boxed ul {
    border-bottom: 2px solid var(--grey-lighter);
    font-weight: 700;

    & li {
      margin: .075rem .1rem;
      width: 49%;
      background-color: var(--white);

      &.is-active a {
        border: none;
        border-bottom: var(--primary) 2px solid !important;
      }
    }
  }
}

</style>
