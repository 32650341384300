<template>
    <AuctionStat 
        name="Offers"
        icon="gavel"
        :value="offerCount"
        :showLabel="showLabel"
        useIcon
    />
    <!-- TODO change the icon; hand-extended is not available on mdi -->
</template>

<script>
import { defineComponent } from "vue";
import AuctionStat from './AuctionStat.vue';

export default defineComponent({
    name: 'TheListingOffersCount',
    props: {
        offerCount: {
            type: Number,
            required: true,
        },
        showLabel: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        AuctionStat,
    },
});
</script>